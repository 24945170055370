import { createSlice } from '@reduxjs/toolkit';

export const IncidentFilterSlice = createSlice({
  name: 'incidentFilter',
  initialState: {
    value: {
      incidentType:[],
      severity: [],
      incidentDate:'',
      startDate:'',
      endDate: '',
      district: [],
      block: [],
      river:[],
      dateFilterLabel: '',
    },
  },
  reducers: {
    IncidentFilterType: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { IncidentFilterType } = IncidentFilterSlice.actions;

export default IncidentFilterSlice.reducer;
