import Backend from 'i18next-http-backend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import alertsEN from 'src/i18n/en/alerts.json';
import alertsHI from 'src/i18n/hi/alerts.json';
import authEN from 'src/i18n/en/auth.json';
import authHI from 'src/i18n/hi/auth.json';
import commonEN from 'src/i18n/en/common.json';
import commonHI from 'src/i18n/hi/common.json';
import dashboardEN from 'src/i18n/en/dashboard.json';
import dashboardHI from 'src/i18n/hi/dashboard.json';
import dataDrilldownEN from 'src/i18n/en/dataDrilldown.json';
import dataDrilldownHI from 'src/i18n/hi/dataDrilldown.json';
import incidentsEN from 'src/i18n/en/incidents.json';
import incidentsHI from 'src/i18n/hi/incidents.json';
import nepalNewsEN from 'src/i18n/en/nepalNews.json';
import nepalNewsHI from 'src/i18n/hi/nepalNews.json';
import profileEN from 'src/i18n/en/profile.json';
import profileHI from 'src/i18n/hi/profile.json';
import rainfallEN from 'src/i18n/en/rainfall.json';
import rainfallHI from 'src/i18n/hi/rainfall.json';
import waterLevelEN from 'src/i18n/en/waterLevel.json';
import waterLevelHI from 'src/i18n/hi/waterLevel.json';

if (!localStorage.getItem('i18nextLng')) {
	localStorage.setItem('i18nextLng', 'hi');
}
i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: 'en',
		lng:
			localStorage && localStorage.getItem('i18nextLng')
				? localStorage.getItem('i18nextLng')?.toString()
				: 'hi',
		interpolation: {
			escapeValue: false,
		},
		resources: {
			en: {
				alerts: alertsEN,
				auth: authEN,
				common: commonEN,
				dashboard: dashboardEN,
				dataDrilldown: dataDrilldownEN,
				incidents: incidentsEN,
				nepalNews: nepalNewsEN,
				profile: profileEN,
				rainfall: rainfallEN,
				waterLevel: waterLevelEN,
			},
			hi: {
				alerts: alertsHI,
				auth: authHI,
				common: commonHI,
				dashboard: dashboardHI,
				dataDrilldown: dataDrilldownHI,
				incidents: incidentsHI,
				nepalNews: nepalNewsHI,
				profile: profileHI,
				rainfall: rainfallHI,
				waterLevel: waterLevelHI,
			},
		},
	});

export default i18n;
