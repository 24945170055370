export const ENGLISH = 'en';
export const HINDI = 'hi';

export const LAST_24_HOURS = 'last 24 hours';
export const LAST_WEEK = 'last 7 days';
export const LAST_MONTH = 'last 30 days';
export const LAST_MONSOON_SEASON = 'last monsoon season';
export const LAST_SIX_MONTH = 'last six months';
export const LAST_YEAR = 'last year';
export const CUSTOM_RANGE = 'custom';
export const CUMULATIVE = 'cumulative';
export const PERDAY = 'perday';
export const PERWEEK = 'perweek';
export const PERMONTH = 'permonth';
export const PERQUARTER = 'perquarter';
export const MONSOON_MONTH = 'monsoon month';
export const TYPE_OBJECT = [
	{
		value: 'last 24 hours',
		label: 'Last24hours',
	},
	{
		value: 'last 7 days',
		label: 'Last7days',
	},
	{
		value: 'last 30 days',
		label: 'Last30days',
	},
	{
		value: 'last monsoon season',
		label: 'Lastmonsoonseason',
	},
	{
		value: 'last year',
		label: 'Lastyear',
	},
	{
		value: 'custom',
		label: 'Customtime',
	},
];

export const STATION = 'station';
export const CWC_STATION = 'CWC Station';
export const MMC_STATION = 'MMC Station';
export const BARRAGE = 'Barrage';

export const HOURLY = 'hourly';
export const SITE = 'site';

export const YES = 'yes';
export const NO = 'no';

export const REPORTTIMESELECT = [
	{ id: 1, label: 'Morning' },
	{ id: 2, label: 'Evening' },
];

export const BASIN = 'basin';

export const ALL_ALERTS = 'All';
export const ALERT_EXPIRY = [{ label: 'Custom', value: 'custom' }];
export const ALERT_DATE = [
	{ label: 'Last24hours', value: '24 hours' },
	{ label: 'Last7days', value: '7 days' },
	{ label: 'Last30days', value: '30 days' },
	{ label: 'Custom', value: 'custom' },
];

export const ALERT_SEVERITY = ['Warning', 'Alert', 'Watch', 'No Warning'];
export const ALERT_TARGET = ['Department', 'District', 'Public'];
export const ALERT_STATUS = ['Active', 'Closed'];
export const ALERT_CATEGORY = [
	{ label: 'Current', value: 'CURRENT' },
	{ label: 'Prediction', value: 'PREDICTION' },
];
export const CURRENT = 'CURRENT';

export const IRRIGATION = 'irrigation';
export const FLOOD = 'flood';

export const RAINFALL_TYPE_ALERT = 'rainfall';
export const WATER_LEVEL_TYPE_ALERT = 'water level';
export const WATER_DISCHARGE_TYPE_ALERT = 'water discharge';
export const DEPARTMENTALERT = 'department';
export const INCIDENTALERT = 'incident';
export const AUTOMATEDALERT = 'automated';
export const GENERAL_TYPE_ALERT = 'general alert';
export const OTHERTITLE = 'other';

// Nepal watch
export const BELOW_WARNING_LEVEL = 'BELOW WARNING LEVEL';
export const AT_WARNING_LEVEL = 'AT WARNING LEVEL';
export const AT_DANGER_LEVEL = 'AT DANGER LEVEL';

export const NEPAL_ALL = 'All';
export const ALL = 'all';
export const CUSTOM = 'custom';

// Incident Tabs
export const ALL_INCIDENTS = 'All';
export const NEW_INCIDENTS = 'New';
export const INPROGRESS_INCIDENTS = 'InProgress';
export const REOPENED_INCIDENTS = 'Reopen';
export const CLOSED_INCIDENTS = 'Closed';
export const ASSIGNED_INCIDENTS = 'assigned_to_me';
export const REPORTED_INCIDENTS = 'reported_by_me';
export const REOPENING = 'Reopened';
// Incident Severity
export const HIGH_INCIDENTS = 'High';
export const LOW_INCIDENTS = 'Low';
export const MEDIUM_INCIDENTS = 'Medium';

export const LEFTMENU = [
	{ id: '1000001', title: 'Water Resource Department (WRD)' },
	{ id: '1000002', title: 'FMISC' },
	{ id: '1000003', title: 'Administration' },
	{ id: '1000004', title: 'Org 4' },
	{ id: '1000005', title: 'Org 5' },
	{ id: '1000006', title: 'Org 6' },
];

// To be removed as per BE Enums
export const NORAINFALL = 'No Rainfall';

export const VERYLIGHTRAINFALL = 'Very Light Rainfall';
export const LIGHTRAINFALL = 'Light Rainfall';
export const MODERATERAINFALL = 'Moderate Rainfall';
export const HEAVYRAINFALL = 'Heavy Rainfall';
export const VERYHEAVYRAINFALL = 'Very Heavy Rainfall';
export const EXTREMELYHEAVYRAINFALL = 'Extremely Heavy Rainfall';

export const DEPARTMENTADMIN = 'Department Admin';

export const NORMALTREND = 'No Warning';

export const ALERTTREND = 'Alert';

export const DANGERTREND = 'Warning';

export const RISINGTREND = 'Rising';
export const STEADYTREND = 'Steady';
export const FALLINGTREND = 'Falling';
export const DANGERWATERTREND = 'Danger';

export const ALERT = 'Alert';
export const NOWARNING = 'No Warning';
export const WATCH = 'Watch';
export const WARNING = 'Warning';
export const NORMAL = 'Normal';
export const DANGER = 'Danger';
export const HFL = 'H.F.L';
export const USER = ['District Magistrate', 'User'];

export const OFFICIALS = [
	'Chief Engineer',
	'Superintending Engineer',
	'Assistant Engineer',
	'Executive Engineer',
	'Junior Engineer',
	'Incident Manager',
];

export const HIGHER_OFFICIALS = 'higher officials';
export const JE = 'Junior Engineer';
export const DA = 'Department Admin';
export const CE = 'Chief Engineer';
export const SE = 'Superintending Engineer';
export const EE = 'Executive Engineer';
export const AE = 'Assistant Engineer';
export const DM = 'District Magistrate';
export const EIC = 'Engineer-In-Chief';
export const SEFM = 'SE - Flood Monitoring';

export const SECTIONROWS = [
	{ id: 1, sections: 'name', je: 'name', contact: 'email', subDivision: 'name', incidents: 12 },
	{ id: 2, sections: 'name', je: 'name', contact: 'email', subDivision: 'name', incidents: 12 },
	{ id: 3, sections: 'name', je: 'name', contact: 'email', subDivision: 'name', incidents: 12 },
	{ id: 4, sections: 'name', je: 'name', contact: 'email', subDivision: 'name', incidents: 12 },
	{ id: 5, sections: 'name', je: 'name', contact: 'email', subDivision: 'name', incidents: 12 },
	{ id: 6, sections: 'name', je: 'name', contact: 'email', subDivision: 'name', incidents: 12 },
];

export const CLOSED = 'Closed';

export const ACTIVEMEDIA = 'active';
export const REOPENMEDIA = 'reopen';

export const LOGIN = 'login';
export const SIGNUP = 'signup';
export const RESEND = 'resend';
export const RESETPASSWORD = 'reset';
export const UNVERIFIED = 'Email Not Verified';
export const PENDING = 'You account status is on pending, please contact admin!';

export const EXPIRYLIST = [
	{ label: '06hours', value: 6 },
	{ label: '12hours', value: 12 },
	{ label: '24hours', value: 24 },
	{ label: '48hours', value: 48 },
	{ label: 'NoExpiry', value: 0 },
];

export const TARGETLIST = [
	{ label: 'Department', value: 'Department' },
	{ label: 'District', value: 'District' },
	{ label: 'Public', value: 'Public' },
];

export const MONTH_NAMES = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const INCIDENT_SEVERITY = [
	{ label: 'All', value: 'All' },
	{ label: 'High', value: 'High' },
	{ label: 'Medium', value: 'Medium' },
	{ label: 'Low', value: 'Low' },
];

export const INCIDENT_SEVERITY_VARIANT = [
	{ label: 'High', value: 'High' },
	{ label: 'Medium', value: 'Medium' },
	{ label: 'Low', value: 'Low' },
];

export const REPORTED_BY_FILTER_OPTIONS = [
	{ value: 'All', label: 'All' },
	{ value: 'Self', label: 'Me' },
	{ value: 'Department', label: 'Department' },
	{ value: 'District', label: 'District' },
	{ value: 'Public', label: 'Public' },
];

export const INCIDENT_STATUS = [
	{ label: 'inProgress', value: 'InProgress' },
	{ label: 'closed', value: 'Closed' },
	{ label: 'reopened', value: 'Reopen' },
	{ label: 'new', value: 'New' },
];

export const JURISDICTION_RIVER_TYPE = [
	{ label: 'All', value: 'All' },
	{ label: 'Barrages', value: 'Barrage' },
	{ label: 'CWCStations', value: 'CWC Station' },
	{ label: 'MMCStations', value: 'MMC Station' },
	// { label: 'Dams', value: 'Dam' },
];

// Rainfall enums
export const RAINFALL_VERY_LIGHT = 'Very Light Rainfall';
export const RAINFALL_LIGHT = 'Light Rainfall';
export const RAINFALL_MODERATE = 'Moderate Rainfall';
export const RAINFALL_HEAVY = 'Heavy Rainfall';
export const RAINFALL_VERY_HEAVY = 'Very Heavy Rainfall';
export const RAINFALL_EXTREMELY_HEAVY = 'Extremely Heavy Rainfall';

// Alert severity
export const RAINFALL_NORMAL = 'No Warning';
export const RAINFALL_WATCH = 'Watch';
export const RAINFALL_ALERT = 'Alert';
export const RAINFALL_WARNING = 'Warning';
export const WATER_NORMAL = 'Normal Level';
export const WATER_WARNING = 'Warning Level';
export const WATER_DANGER = 'Danger Level';
export const WATER_HFL = 'High Flood Level';

// Water details
export const WATER_TYPES = [
	{
		label: 'Barrages',
		value: 'Barrage',
	},
	{
		label: 'CWCStations',
		value: 'CWC Station',
	},
	{
		label: 'Dams',
		value: 'Dams',
	},
	{ label: 'MMCStations', value: 'MMC Station' },
];

export const WATERLEVEL_TABLE_TIMINGS = [
	'2 AM',
	'4 AM',
	'6 AM',
	'8 AM',
	'10 AM',
	'12 PM',
	'2 PM',
	'4 PM',
	'6 PM',
	'8 PM',
	'10 PM',
	'12 AM',
];

export const WATERLEVEL_TABLE_TIMINGS_VARIANT = [
	'12AM - 2AM',
	'2AM - 4AM',
	'4AM - 6AM',
	'6AM - 8AM',
	'8AM - 10AM',
	'10AM - 12PM',
	'12PM - 2PM',
	'2PM - 4PM',
	'4PM - 6PM',
	'6PM - 8PM',
	'8PM - 10PM',
	'10PM - 12AM',
];
export const GATEPOSTION_TABLE_TIMINGS = [
	'12 AM',
	'2 AM',
	'4 AM',
	'6 AM',
	'8 AM',
	'10 AM',
	'12 PM',
	'2 PM',
	'4 PM',
	'6 PM',
	'8 PM',
];

export const NOTIFICATION_FILTER_LIST = [
	{ label: 'All', value: '0' },
	{ label: 'alerts', value: '1' },
	{ label: 'incidents', value: '2' },
];

export const RAINFALL_DATA_TYPES = [
	{ label: 'block', value: 'block' },
	{ label: 'district', value: 'district' },
];

export const RT_NORMAL = 'Normal Level';
export const RT_UNRELIABLE = 'Unreliable Level';
export const RT_WARNING = 'Warning Level';
export const RT_DANGER = 'Danger Level';
export const RT_HFL = 'HFL Level';
export const RT_DRY = 'Dry';
export const RT_ERROR = 'Error';
export const RT_NOTRESPONDING = 'Not Responding';

export const CAPTCHA_AUTH_KEY = '6Lfr7i4lAAAAALUJ4b2bSXToB_M70eznE4TJ-GRK';
export const ARC_GIS_KEY =
	'AAPK57967c4a1a2c4e8e91435ab046280314GQrHLNBqngtz2gsg1kklAOM6sLxmwOXs_Faa2a2RA289WBZEUty5zldS-BkBw5gf';
export const ARC_GIS_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer';
export const GOOGLE_ANALYTICS_KEY_PROD = 'G-0KNYS13YFF';
export const GOOGLE_ANALYTICS_KEY_TEST = 'G-4X9BCCH4L2';
export const WEATHER_API_KEY = '4b1b60deb4msh10db85477124736p176716jsn50274b791954';

export const GRAPHICAL_STATE_LINKS = [
	{
		linkEn: 'https://irrigation.befiqr.in/flood/graphical/sone-barrage?platform=mobile&language=en',
		linkHi: 'https://irrigation.befiqr.in/flood/graphical/sone-barrage?platform=mobile&language=hi',
		label: 'Barrage',
	},
	{
		linkEn: 'https://irrigation.befiqr.in/flood/graphical/rivers?platform=mobile&language=en',
		linkHi: 'https://irrigation.befiqr.in/flood/graphical/rivers?platform=mobile&language=hi',
		label: 'River',
	},
	{
		linkEn:
			'https://irrigation.befiqr.in/flood/graphical/bmsk-district-rainfall?platform=mobile&language=en',
		linkHi:
			'https://irrigation.befiqr.in/flood/graphical/bmsk-district-rainfall?platform=mobile&language=hi',
		label: 'District Rainfall',
	},
	{
		linkEn:
			'https://irrigation.befiqr.in/flood/graphical/bmsk-block-rainfall?platform=mobile&language=en',
		linkHi:
			'https://irrigation.befiqr.in/flood/graphical/bmsk-block-rainfall?platform=mobile&language=hi',
		label: 'Block Rainfall',
	},
	{
		linkEn:
			'https://irrigation.befiqr.in/flood/graphical/rainfall-prediction?platform=mobile&language=en',
		linkHi:
			'https://irrigation.befiqr.in/flood/graphical/rainfall-prediction?platform=mobile&language=hi',
		label: 'Rainfall Prediction',
	},
];

export const TABULAR_STATE_LINKS = [
	{
		linkEn: 'https://irrigation.befiqr.in/flood/table/rtdas-stations?platform=mobile&language=en',
		linkHi: 'https://irrigation.befiqr.in/flood/table/rtdas-stations?platform=mobile&language=hi',
		label: 'RTDAS Station',
	},
	{
		linkEn: 'https://irrigation.befiqr.in/flood/table/barrages?platform=mobile&language=en',
		linkHi: 'https://irrigation.befiqr.in/flood/table/barrages?platform=mobile&language=hi',
		label: 'Barrages',
	},
	{
		linkEn: 'https://irrigation.befiqr.in/flood/table/cwc-stations?platform=mobile&language=en',
		linkHi: 'https://irrigation.befiqr.in/flood/table/cwc-stations?platform=mobile&language=hi',
		label: 'CWC Stations',
	},
	{
		linkEn:
			'https://irrigation.befiqr.in/flood/table/bmsk-district-rainfall?platform=mobile&language=en',
		linkHi:
			'https://irrigation.befiqr.in/flood/table/bmsk-district-rainfall?platform=mobile&language=hi',
		label: 'BMSK Rainfall',
	},
	{
		linkEn: 'https://irrigation.befiqr.in/flood/table/nepal-rainfall?platform=mobile&language=en',
		linkHi: 'https://irrigation.befiqr.in/flood/table/nepal-rainfall?platform=mobile&language=hi',
		label: 'Nepal Stations',
	},
];

export const IFRAME_LINKS = [
	{
		linkEn: 'https://www.befiqr.in/app/iframe/barrage?platform-mobile&language=en',
		linkHi: 'https://www.befiqr.in/app/iframe/barrage?platform-mobile&language=hi',
		label: 'Barrages',
	},
	{
		linkEn: 'https://www.befiqr.in/app/iframe/rtdas?platform-mobile&language=en',
		linkHi: 'https://www.befiqr.in/app/iframe/rtdas?platform-mobile&language=hi',
		label: 'RTDAS Station',
	},
	{
		linkEn: 'https://www.befiqr.in/app/iframe/cwc?platform-mobile&language=en',
		linkHi: 'https://www.befiqr.in/app/iframe/cwc?platform-mobile&language=hi',
		label: 'CWC Stations',
	},
	{
		linkEn: 'https://irrigation.befiqr.in/flood/table/water-level-obs?platform-mobile&language=en',
		linkHi: 'https://irrigation.befiqr.in/flood/table/water-level-obs?platform-mobile&language=hi',
		label: 'WRD Stations',
	},
];

export const LOGOUT = 'logout';
export const LOGGEDIN = 'loggedIn';

export const NEXT_7_DAY_KEY = 'alerts:Next7days';
export const TODAY_KEY = 'common:today';
export const TOMORROW_KEY = 'common:tomorrow';
export const LAST_2_DAY_KEY = 'alerts:Last2days';
export const NEXT_2_DAY_KEY = 'alerts:Next2days';
export const LAST_7_DAY_KEY = 'alerts:Last7days';
export const YESTERDAY_KEY = 'common:yesterday';
export const LAST_30_DAY_KEY = 'alerts:Last30days';
export const LAST_MONSOOM_KEY = 'alerts:Lastmonsoonseason';
export const LAST_YEAR_KEY = 'alerts:Lastyear';
export const CUSTOM_RANGE_KEY = 'alerts:Custom';
