import React from 'react';

import { Box, styled, Typography } from '@mui/material';
import Clock from 'react-live-clock';

const FlexWrapper = styled(Box)(() => ({
	display: 'flex',
}));

export default function DateView() {
	const locale = 'en';
	const [today] = React.useState(new Date());
	const day = today.toLocaleDateString(locale, { weekday: 'long' });
	const date = `${today.getDate()}-${today.toLocaleDateString(locale, {
		month: 'short',
	})}`;
	const year = today.getFullYear();

	return (
		<FlexWrapper>
			<Typography variant="body1" color="white">
				{date}-{year}
			</Typography>
			<Typography variant="body1" color="white" px={1}>
				|
			</Typography>
			<Typography variant="body1" color="white">
				{day}
			</Typography>
			<Typography variant="body1" color="white" px={1}>
				|
			</Typography>
			<Typography variant="body1" color="white">
				<Clock format={'h:mm A'} ticking={true} timezone={'Asia/Kolkata'} />
			</Typography>
		</FlexWrapper>
	);
}
