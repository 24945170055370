import { SettingsValueProps } from './components/settings/type';

export const HOST_API = process.env.REACT_APP_API_SERVER_URL || '';
export const CONTEXT_PATH = process.env.PUBLIC_URL;

export const HEADER = {
	MOBILE_HEIGHT: 84,
	MAIN_DESKTOP_HEIGHT: 78,
	DASHBOARD_DESKTOP_HEIGHT: 70,
	DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
	BASE_WIDTH: 260,
	DASHBOARD_WIDTH: 220,
	DASHBOARD_COLLAPSE_WIDTH: 68,
	//
	DASHBOARD_ITEM_ROOT_HEIGHT: 48,
	DASHBOARD_ITEM_SUB_HEIGHT: 40,
	DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
	NAVBAR_ITEM: 22,
	NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
	themeMode: 'light',
	themeDirection: 'ltr',
	themeColorPresets: 'blue',
	themeLayout: 'horizontal',
	themeStretch: false,
};
