import { createSlice } from '@reduxjs/toolkit';

export const waterBodySlice = createSlice({
  name: 'waterBody',
  initialState: {
    value: {
        type: '',
        id:'',
    },
  },
  reducers: {
    waterBodyDetails: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { waterBodyDetails } = waterBodySlice.actions;

export default waterBodySlice.reducer;
