import { pxToRem, responsiveFontSizes } from '../../common/utils/getFontValue';

const FONT_PRIMARY = 'Mulish, sans-serif'; // Local Font

const typography = {
	fontFamily: FONT_PRIMARY,
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 500,
	fontWeightSemiBold: 600,
	fontWeightBold: 700,
	h1: {
		fontWeight: 500,
		lineHeight: 80 / 64,
		fontSize: pxToRem(48),
		letterSpacing: 2,
		...responsiveFontSizes({ sm: 42, md: 48, lg: 50 }),
	},
	h2: {
		fontWeight: 600,
		lineHeight: 64 / 48,
		fontSize: pxToRem(25),
		...responsiveFontSizes({ sm: 24, md: 26, lg: 28 }),
	},
	h3: {
		fontWeight: 600,
		lineHeight: 1.5,
		fontSize: pxToRem(23),
		...responsiveFontSizes({ sm: 20, md: 23, lg: 25 }),
	},
	h4: {
		fontWeight: 700,
		lineHeight: 1.5,
		fontSize: pxToRem(20),
		...responsiveFontSizes({ sm: 19, md: 20, lg: 21 }),
	},
	h5: {
		fontWeight: 300,
		lineHeight: 1.5,
		fontSize: pxToRem(19),
		...responsiveFontSizes({ sm: 18, md: 19, lg: 21 }),
	},
	h6: {
		fontWeight: 700,
		lineHeight: 28 / 18,
		fontSize: pxToRem(18),
		...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
	},
	subtitle1: {
		fontWeight: 500,
		lineHeight: 1.5,
		fontSize: pxToRem(14),
	},
	subtitle2: {
		fontWeight: 600,
		lineHeight: 22 / 14,
		fontSize: pxToRem(16),
	},
	body1: {
		lineHeight: 1.5,
		fontSize: pxToRem(15),
		fontWeight: 500,
	},
	body2: {
		lineHeight: 22 / 14,
		fontSize: pxToRem(15),
		fontWeight: 600,
	},
	caption: {
		lineHeight: 1.5,
		fontSize: pxToRem(13),
	},
	overline: {
		lineHeight: 1.5,
		fontSize: pxToRem(12),
		textTransform: 'unset',
	},
	button: {
		fontWeight: 600,
		lineHeight: 24 / 14,
		fontSize: pxToRem(16),
		textTransform: 'capitalize',
	},
} as const;

export default typography;
