import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

// routes
import { DOMAIN_PATHS } from 'src/routes/paths';
// hooks
import useAuth from 'src/common/hooks/useAuth';

// ----------------------------------------------------------------------

type GuestGuardProps = {
	children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
	const { isAuthenticated } = useAuth();
	if (isAuthenticated) {
		return <Navigate to={DOMAIN_PATHS.preference} />;
	}

	return <>{children}</>;
}
