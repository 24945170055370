import { Theme } from '@mui/material/styles';

//
//
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select(theme: Theme) {
	return {
		MuiSelect: {
			defaultProps: {
				IconComponent: InputSelectIcon,
				MenuProps: {
					PaperProps: {
						style: {
							borderRadius: '4px',
							maxHeight: '16rem',
						},
						sx: {
							'.MuiPopover-paper': {
								maxHeight: '100%',
								overflow: 'auto',
								overflowX: 'hidden',
								overflowY: 'scroll',
								'&::-webkit-scrollbar': {
									width: '0.3em',
								},
								'&::-webkit-scrollbar-track': {
									boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
									backgroundColor: 'white',
									borderRadius: '10px',
								},
								'&::-webkit-scrollbar-thumb': {
									borderRadius: '10px',
									backgroundColor: theme.palette.primary.main,
								},
							},
						},
						// sx: {
						// 	'& .MuiMenuItem-root.Mui-selected': {
						// 		color: theme.palette.primary.lighter,
						// 		backgroundColor: theme.palette.primary.main,
						// 	},
						// 	'& .MuiMenuItem-root:hover': {
						//     color: theme.palette.primary.lighter,
						// 		backgroundColor: theme.palette.primary.main,
						// 	},
						// 	'& .MuiMenuItem-root.Mui-selected:hover': {
						// 		backgroundColor: theme.palette.primary.main,
						// 		color: theme.palette.primary.lighter,
						// 	},
						// },
					},
				},
			},
		},
	};
}
