const path = (root: string, sublink: string) => `${root}${sublink}`;

const CONTEXT_PATH = '';
const AUTH_ROUTES_ROOT = CONTEXT_PATH + '/auth';
const REGISTER_ROUTES_ROOT = CONTEXT_PATH + '/register';

export const DOMAIN_PATHS = {
	root: CONTEXT_PATH,
	overview: path(CONTEXT_PATH, '/dashboard'),
	preference: path(CONTEXT_PATH, '/preference'),
	profile: path(CONTEXT_PATH, '/profile'),
	alerts: path(CONTEXT_PATH, '/alerts'),
	closeAlerts: path(CONTEXT_PATH, '/close-alerts'),
	createAlerts: path(CONTEXT_PATH, '/create-alerts'),
	viewAlerts: path(CONTEXT_PATH, '/view-alerts'),
	rainfallDetails: path(CONTEXT_PATH, '/rainfall-details'),
	nepalNews: path(CONTEXT_PATH, '/nepal-rainfall-watch'),
	waterLevelDetails: path(CONTEXT_PATH, '/waterlevel-details'),
	waterDischargeDetails: path(CONTEXT_PATH, '/waterdischarge-details'),
	incidentManagement: path(CONTEXT_PATH, '/incident'),
	createIncidents: path(CONTEXT_PATH, '/create-incidents'),
	viewIncidents: path(CONTEXT_PATH, '/view-incidents'),
	dataDrilldown: path(CONTEXT_PATH, '/data-drilldown'),
	departmentJurisdiction: path(CONTEXT_PATH, '/data-drilldown/department-jurisdiction'),
	districtJurisdiction: path(CONTEXT_PATH, '/data-drilldown/district-jurisdiction'),
	riverAssets: path(CONTEXT_PATH, '/data-drilldown/river-and-assets'),
	riverDistribution: path(CONTEXT_PATH, '/data-drilldown/river-distribution'),
	weatherCalender: path(CONTEXT_PATH, '/weather-calender'),
	privacy: path(CONTEXT_PATH, '/privacy-policy'),
	terms: path(CONTEXT_PATH, '/terms-and-conditions'),
	copyright: path(CONTEXT_PATH, '/copyright-policy'),
	disclaimer: path(CONTEXT_PATH, '/disclaimer'),
	stateView: path(CONTEXT_PATH, '/state-view'),
	graphBarrages: path(CONTEXT_PATH, '/state-view/graphical-barrages'),
	tabularBarrages: path(CONTEXT_PATH, '/state-view/tabular-barrages'),
	blockRainfall: path(CONTEXT_PATH, '/state-view/block-rainfall'),
	districtRainfall: path(CONTEXT_PATH, '/state-view/district-rainfall'),
	bmskRainfall: path(CONTEXT_PATH, '/state-view/bmsk-rainfall'),
	cwcStations: path(CONTEXT_PATH, '/state-view/cwc-stations'),
	nepalRainfall: path(CONTEXT_PATH, '/state-view/nepal-rainfall'),
	rainfallPrediction: path(CONTEXT_PATH, '/state-view/rainfall-prediction'),
	rivers: path(CONTEXT_PATH, '/state-view/rivers'),
	rtdasStations: path(CONTEXT_PATH, '/state-view/rtdas-stations'),
};

export const AUTH_PATHS = {
	root: AUTH_ROUTES_ROOT,
	loginPhone: path(AUTH_ROUTES_ROOT, ''),
	loginOTP: path(AUTH_ROUTES_ROOT, '/otp'),
	resetPasswordLink: path(AUTH_ROUTES_ROOT, '/resetPasswordLink'),
	resetPassword: path(AUTH_ROUTES_ROOT, '/resetPassword'),
	createPassword: path(AUTH_ROUTES_ROOT, '/createPassword'),
	profileDetails: path(AUTH_ROUTES_ROOT, '/profileDetails'),

	status: path(AUTH_ROUTES_ROOT, '/status'),
};

export const REGISTER_PATHS = {
	root: AUTH_ROUTES_ROOT,
	register: path(REGISTER_ROUTES_ROOT, ''),
	contractSignup: path(REGISTER_ROUTES_ROOT, '/contractSignup'),
	generalDetails: path(REGISTER_ROUTES_ROOT, '/generalDetails'),
	jurisdictionDetails: path(REGISTER_ROUTES_ROOT, '/jurisdictionDetails'),
	splashregister: path(REGISTER_ROUTES_ROOT, '/splash-register'),
	location: path(REGISTER_ROUTES_ROOT, '/location'),
	details: path(REGISTER_ROUTES_ROOT, '/details'),
};
