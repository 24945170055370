import { NavLink as RouterLink } from 'react-router-dom';

import { Box, Link, ListItemText, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './style';
import Iconify from 'src/components/Iconify';
import { isExternalLink } from '..';
import { NavItemProps } from '../type';

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }: NavItemProps) {
	const { title, path, icon, info, children } = item;

	const { t } = useTranslation();

	const renderContent = (
		<>
			{icon && (
				<Tooltip title={t(`common:${title}`)}>
					<ListItemIconStyle>{icon}</ListItemIconStyle>
				</Tooltip>
			)}
			<ListItemTextStyle disableTypography primary={t(`common:${title}`)} isCollapse={isCollapse} />
			{!isCollapse && (
				<>
					{info && info}
					{children && <ArrowIcon open={open} />}
				</>
			)}
		</>
	);

	if (children) {
		return (
			<ListItemStyle onClick={onOpen} activeRoot={active}>
				{renderContent}
			</ListItemStyle>
		);
	}

	return isExternalLink(path) ? (
		<ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
			{renderContent}
		</ListItemStyle>
	) : (
		<ListItemStyle component={RouterLink} to={path} activeRoot={active}>
			{renderContent}
		</ListItemStyle>
	);
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, 'isCollapse'>;

export function NavItemSub({ item, open = false, active = false, onOpen }: NavItemSubProps) {
	const { title, path, info, children } = item;

	const { t } = useTranslation();

	const renderContent = (
		<>
			<DotIcon active={active} />
			<ListItemText disableTypography primary={t(`common:${title}`)} />
			{info && info}
			{children && <ArrowIcon open={open} />}
		</>
	);

	if (children) {
		return (
			<ListItemStyle onClick={onOpen} activeSub={active} subItem>
				{renderContent}
			</ListItemStyle>
		);
	}

	return isExternalLink(path) ? (
		<ListItemStyle component={Link} href={path} target="_blank" rel="noopener" subItem>
			{renderContent}
		</ListItemStyle>
	) : (
		<ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
			{renderContent}
		</ListItemStyle>
	);
}

// ----------------------------------------------------------------------

type DotIconProps = {
	active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
	return (
		<ListItemIconStyle>
			<Box
				component="span"
				sx={{
					width: 4,
					height: 4,
					borderRadius: '50%',
					bgcolor: 'red',
					visibility: 'hidden',
					transition: (theme) =>
						theme.transitions.create('transform', {
							duration: theme.transitions.duration.shorter,
						}),
					...(active && {
						transform: 'scale(2)',
						bgcolor: 'white',
					}),
				}}
			/>
		</ListItemIconStyle>
	);
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
	open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
	return (
		<Iconify
			icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
			sx={{ width: 16, height: 16, ml: 1 }}
		/>
	);
}
