import { createSlice } from '@reduxjs/toolkit';
import { ALL } from 'src/common/utils/constants';

export const AlertFilterSlice = createSlice({
  name: 'alertFilter',
  initialState: {
    value: {
      type: ALL,
      alertType:[],
      severity: [],
      target: [],
      status: [],
      alertDate:'',
      startDate:'',
      endDate: '',
      startExpiryDate: '',
      endExpiryDate: '',
      district: [],
      block: [],
      river:[],
      expiryFilterLabel: '',
      dateFilterLabel: '',
    },
  },
  reducers: {
    AlertFilterType: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { AlertFilterType } = AlertFilterSlice.actions;

export default AlertFilterSlice.reducer;
