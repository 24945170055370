import { alpha } from '@mui/material/styles';

const createGradient = (color1: string, color2: string) =>
	`linear-gradient(to bottom, ${color1}, ${color2})`;

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
	primary: string;
	info: string;
	success: string;
	warning: string;
	error: string;
}

interface ChartPaletteOptions {
	violet: string[];
	blue: string[];
	green: string[];
	yellow: string[];
	red: string[];
}

declare module '@mui/material/styles/createPalette' {
	interface TypeBackground {
		neutral: string;
	}

	interface SimplePaletteColorOptions {
		lighter: string;
		darker: string;
	}

	interface PaletteColor {
		lighter: string;
		darker: string;
	}

	interface Palette {
		gradients: GradientsPaletteOptions;
		chart: ChartPaletteOptions;
	}

	interface PaletteOptions {
		gradients: GradientsPaletteOptions;
		chart: ChartPaletteOptions;
	}
}

declare module '@mui/material' {
	interface Color {
		0: string;
		500_8: string;
		500_12: string;
		500_16: string;
		500_24: string;
		500_32: string;
		500_48: string;
		500_56: string;
		500_80: string;
	}
}

// COLOR SCHEME
const PRIMARY = {
	lighter: '#F1F1F1',
	light: '#E9F0F7',
	main: '#2565AE',
	dark: '#2B5480',
	darker: '#1E3B59',
};
const SECONDARY = {
	lighter: '#EBECF7',
	light: '#C7C8D1',
	main: '#DFE0EB',
	dark: '#A2A3AB',
	darker: '#66666B',
};
const INFO = {
	lighter: '#FCE4B9',
	light: '#FFBD00',
	main: '#FFA600',
	dark: '#E69500',
	darker: '#BF7C00',
};
const SUCCESS = {
	lighter: '#DFF1D8',
	light: '#47FF47',
	main: '#31B231',
	dark: '#2B992B',
	darker: '#207320',
};
const WARNING = {
	lighter: '#FDFECD',
	light: '#DBBA00',
	main: '#CFB101',
	dark: '#B59A00',
	darker: '#8F7900',
};
const ERROR = {
	lighter: '#FFE4DE',
	light: '#DE0000',
	main: '#D20000',
	dark: '#B80000',
	darker: '#910000',
};

const GREY = {
	0: '#fafafa',
	100: '#7A7D81',
	200: '#C5C7CD',
	300: '#DFE3E8',
	400: '#C4CDD5',
	500: '#919EAB',
	600: '#637381',
	700: '#454F5B',
	800: '#212B36',
	900: '#252733',
	500_8: alpha('#919EAB', 0.08),
	500_12: alpha('#919EAB', 0.12),
	500_16: alpha('#919EAB', 0.16),
	500_24: alpha('#919EAB', 0.24),
	500_32: alpha('#919EAB', 0.32),
	500_48: alpha('#919EAB', 0.48),
	500_56: alpha('#919EAB', 0.56),
	500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
	primary: createGradient(PRIMARY.light, PRIMARY.main),
	info: createGradient(INFO.light, INFO.main),
	success: createGradient(SUCCESS.light, SUCCESS.main),
	warning: createGradient(WARNING.light, WARNING.main),
	error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
	violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
	blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
	green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
	yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
	red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
	common: { black: '#000', white: '#fff' },
	primary: { ...PRIMARY, contrastText: '#fff' },
	secondary: { ...SECONDARY, contrastText: '#fff' },
	info: { ...INFO, contrastText: '#fff' },
	success: { ...SUCCESS, contrastText: GREY[800] },
	warning: { ...WARNING, contrastText: GREY[800] },
	error: { ...ERROR, contrastText: '#fff' },
	grey: GREY,
	gradients: GRADIENTS,
	chart: CHART_COLORS,
	divider: GREY[500_24],
	action: {
		hover: GREY[500_8],
		selected: GREY[500_16],
		disabled: GREY[500_80],
		disabledBackground: GREY[500_24],
		focus: GREY[500_24],
		hoverOpacity: 0.08,
		disabledOpacity: 0.48,
	},
};

const palette = {
	light: {
		...COMMON,
		mode: 'light',
		text: { primary: GREY[900], secondary: GREY[600], disabled: GREY[100] },
		background: { paper: '#fff', default: GREY[0], neutral: GREY[200] },
		action: { active: GREY[600], ...COMMON.action },
	},
} as const;

export default palette;
