import { Theme } from '@mui/material/styles';

export default function Button(theme: Theme) {
	return {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform:'none',
					'&:hover': {
						boxShadow: 'none',
					},
				},
				sizeLarge: {
					height: 50,
					fontSize: 18,
					padding: theme.spacing(0, 6),
					borderRadius: '4px',
					[theme.breakpoints.down('sm')]: {
						height: 40,
						fontSize: 16,
						padding: theme.spacing(0, 3),
					},
				},
				sizeMedium: {
					height: 40,
					fontSize: 16,
					padding: theme.spacing(0, 2.5),
					borderRadius: '4px',
					[theme.breakpoints.down('sm')]: {
						height: 38,
						fontSize: 14,
					},
				},
				sizeSmall: {
					height: 30,
					fontSize: 14,
					borderRadius: '4px',
					[theme.breakpoints.down('sm')]: {
						fontSize: 12,
					},
				},
				// contained
				containedInherit: {
					color: theme.palette.grey[800],
					boxShadow: 'none',
					'&:hover': {
						backgroundColor: theme.palette.grey[400],
					},
				},
				containedPrimary: {
					boxShadow: 'none',
				},
				containedSecondary: {
					boxShadow: 'none',
				},
				containedInfo: {
					boxShadow: 'none',
				},
				containedSuccess: {
					boxShadow: 'none',
				},
				containedWarning: {
					boxShadow: 'none',
				},
				containedError: {
					boxShadow: 'none',
				},
				// outlined
				outlinedInherit: {
					border: `1px solid ${theme.palette.grey[500_32]}`,
					'&:hover': {
						backgroundColor: theme.palette.action.hover,
					},
				},
				textInherit: {
					'&:hover': {
						backgroundColor: theme.palette.action.hover,
					},
				},
			},
		},
	};
}
