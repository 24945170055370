import { useState } from 'react';

import { AppBar, Box, styled, Toolbar } from '@mui/material';

import { HEADER, NAVBAR } from 'src/config';
import AccountPopover from './AccountPopover';
import Date from './Date';
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/Iconify';
import Logo from 'src/components/Logo';
import NotificationsPopover from './NotificationsPopover';
import useOffSetTop from 'src/common/hooks/useOffSetTop';
import useResponsive from 'src/common/hooks/useResponsive';
import LanguagePopover from './LanguagePopover';

// ----------------------------------------------------------------------

type RootStyleProps = {
	isCollapse: boolean;
	isOffset: boolean;
	verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
	shouldForwardProp: (prop) =>
		prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
	background: theme.palette.primary.main,
	boxShadow: 'none',
	height: HEADER.MOBILE_HEIGHT,
	zIndex: theme.zIndex.appBar + 1,
	transition: theme.transitions.create(['width', 'height'], {
		duration: theme.transitions.duration.shorter,
	}),
	[theme.breakpoints.up('lg')]: {
		height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
		width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
		...(isCollapse && {
			width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
		}),
		...(isOffset && {
			height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
		}),
		...(verticalLayout && {
			width: '100%',
			height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
			backgroundColor: theme.palette.background.default,
		}),
	},
}));

const ActionWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	borderLeft: '1px solid #fff',
	borderRight: '1px solid #fff',
	padding: theme.spacing(0, 1.5),
	margin: theme.spacing(0, 2, 0, 0),

	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(0, 0.5),
		border: 'none',
	},
}));

const LangWrapper = styled(Box)(({ theme }) => ({
	borderLeft: '1px solid #fff',
	paddingLeft: theme.spacing(1.5),
	marginLeft: theme.spacing(1.5),
	height: '100%',
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(0, 0.5),
		border: 'none',
	},
}));

const MainWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		alignItems: 'flex-end',
		width: '100%',
		paddingRight: theme.spacing(2),
	},
}));

const FlexWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',

	[theme.breakpoints.down('sm')]: {
		marginTop: theme.spacing(0.5),
	},
}));

const NotificationsWrapper = styled(Box)(({ theme }) => ({
	width: '10px',
	height: '10px',
	border: `1px solid white`,
	borderRadius: '50%',
	marginLeft: '-8px',
	marginTop: '-9px',
	backgroundColor: theme.palette.error.main,
}));

// ----------------------------------------------------------------------

type Props = {
	onOpenSidebar: VoidFunction;
	isCollapse?: boolean;
	verticalLayout?: boolean;
};

export default function MainHeader({
	onOpenSidebar,
	isCollapse = false,
	verticalLayout = false,
}: Props) {
	const [notificationCount, setNotificationCount] = useState<number>(0);

	const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

	const isDesktop = useResponsive('up', 'lg');
	const isMobile = useResponsive('down', 'sm');

	return (
		<RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
			<Toolbar
				sx={{
					minHeight: '100% !important',
					px: { xs: 0, sm: 3, lg: 5 },
				}}
			>
				{isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

				{!isDesktop && (
					<IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: '#fff' }}>
						<Iconify icon="eva:menu-2-fill" />
					</IconButtonAnimate>
				)}

				{!isMobile && <Box sx={{ flexGrow: 1 }} />}

				<MainWrapper>
					<Date />
					<LangWrapper>
						<LanguagePopover />
					</LangWrapper>

					<FlexWrapper>
						<ActionWrapper>
							<NotificationsPopover
								setNotificationCount={(count: number) => setNotificationCount(count)}
							/>
							{notificationCount > 0 && <NotificationsWrapper />}
						</ActionWrapper>
						<AccountPopover />
					</FlexWrapper>
				</MainWrapper>
			</Toolbar>
		</RootStyle>
	);
}
