import { ReactNode, useMemo } from 'react';

import {
	createTheme,
	StyledEngineProvider,
	ThemeOptions,
	ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import 'src/i18n/i18n';

import shadows, { customShadows } from './styling/shadows';
import breakpoints from './styling/breakpoints';
import componentsOverride from './overrides';
import palette from './styling/palette';
import typography from './styling/typography';
import useSettings from '../common/hooks/useSettings';

// ----------------------------------------------------------------------

type ThemeProps = {
	children: ReactNode;
};

export default function ThemeProvider({ children }: ThemeProps) {
	const { themeMode, themeDirection } = useSettings();
	const isLight = themeMode === 'light';

	const themeOptions: ThemeOptions = useMemo(
		() => ({
			palette: palette.light,
			typography,
			breakpoints,
			shape: { borderRadius: 8 },
			direction: themeDirection,
			shadows: isLight ? shadows.light : shadows.dark,
			customShadows: isLight ? customShadows.light : customShadows.dark,
		}),
		[isLight, themeDirection]
	);

	const theme = createTheme(themeOptions);
	theme.components = componentsOverride(theme);

	return (
		<StyledEngineProvider injectFirst>
			<MUIThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</MUIThemeProvider>
		</StyledEngineProvider>
	);
}
