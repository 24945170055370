// scroll bar
import 'simplebar/src/simplebar.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

// redux
import { persistor, store } from './common/redux/store';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';

import App from './app';
import { CONTEXT_PATH } from './config';

// contexts
import { CollapseDrawerProvider } from './common/contexts/CollapseDrawerContext';
import { AuthProvider } from './common/contexts/JWTContext';

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

root.render(
	<AuthProvider>
		<HelmetProvider>
			<ReduxProvider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<CollapseDrawerProvider>
						<BrowserRouter basename={CONTEXT_PATH}>
							<App />
						</BrowserRouter>
					</CollapseDrawerProvider>
				</PersistGate>
			</ReduxProvider>
		</HelmetProvider>
	</AuthProvider>
);
