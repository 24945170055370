import {
	FormControl,
	FormHelperText,
	InputLabel,
	styled,
	TextField,
	TextFieldProps,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type TextBoxProps = {
	name: string;
	label: string | React.ReactElement;
	greyVariant?: boolean;
	blackLabel?: boolean;
	hideErrorSpace?: boolean;
	hideLabel?: boolean;
	small?: boolean;
	disabled?: boolean;
	textArea?: boolean;
	helper?: boolean;
}

const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
	marginRight: theme.spacing(0.5),
	fontSize: '1.25rem',
}));

const ErrorSpace = styled(FormHelperText)(({ theme }) => ({
	minHeight: '1.5rem',
	marginLeft: 0,
	marginTop: theme.spacing(0.2),
	display: 'flex',
	alignItems: 'center',
	fontSize: '0.8rem',
}));

const LabelStyle = styled(InputLabel)(({ theme }) => ({
	fontSize: '15px',
	marginBottom: theme.spacing(1),
	color: theme.palette.grey[0],
	fontWeight: 600,
	lineHeight: 22 / 14,
}));

export default function RHFTextField({
	name,
	label,
	greyVariant,
	blackLabel,
	hideErrorSpace,
	hideLabel,
	small,
	disabled,
	textArea,
	helper,
	...other
}: TextBoxProps & TextFieldProps) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<>
					{!hideLabel && (
						<LabelStyle
							htmlFor={name}
							sx={{
								flexWrap: 'wrap',
								color: blackLabel ? '#252733' : 'inherit',
							}}
						>
							{label}
						</LabelStyle>
					)}
					<FormControl fullWidth>
						<TextField
							inputProps={{ maxLength: 150 }}
							variant="outlined"
							autoComplete="off"
							disabled={disabled}
							{...field}
							fullWidth
							size={small || textArea ? 'small' : undefined}
							error={!!error}
							{...other}
							sx={{
								background: greyVariant ? '#F1F1F1' : '#FFF',
								borderRadius: '4px',
								border: '1px solid',
								borderColor: '#DFE0EB',
								height: small ? '40px' : textArea ? '106px' : 'auto',
								padding: small ? '0px' : '',
								paddingRight: helper ? '70px' : '',
								'.MuiFormHelperText-root': {
									position: 'absolute !important',
									right: 0,
									fontSize: '15px',
								},
								'& ::-ms-reveal': {
									display: 'none !important',
								},
							}}
						/>
						{!hideErrorSpace && (
							<ErrorSpace error>
								{error && (
									<>
										<InfoIcon /> {error?.message}
									</>
								)}
							</ErrorSpace>
						)}
					</FormControl>
				</>
			)}
		/>
	);
}
