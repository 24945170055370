import { createSlice } from '@reduxjs/toolkit';

export const contractDetailsSlice = createSlice({
  name: 'auth',
  initialState: {
    value: {
      name: '',
      email:'',
      employeeCode:'',
      image:'',
    },
  },
  reducers: {
    contractDetailsType: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { contractDetailsType } = contractDetailsSlice.actions;

export default contractDetailsSlice.reducer;
