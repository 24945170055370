import {
	Box,
	Button,
	Grid,
	Select,
	styled,
	TableCell,
	TableBody,
	TableHead,
	TextField,
	TextFieldProps,
	Tooltip,
	tooltipClasses,
	TooltipProps,
	Typography,
	SelectProps,
	ButtonProps,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import DoneIcon from '@mui/icons-material/Done';
import { LoadingButton } from '@mui/lab';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';

type CustomTextFieldProps = TextFieldProps & {
	hasoptionselected: any;
  };

type CustomSelectProps = SelectProps & {
	hasoptionselected?: any;
  };

type CustomButtonProps = ButtonProps & {
	hasoptionselected?: any;
  };
  
export const PictureUploadFullWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	height: theme.spacing(14),
	marginBottom: theme.spacing(2),
	cursor: 'pointer',
}));

export const DottedWrapper = styled(Box)(({ theme }) => ({
	border: `2px dashed ${theme.palette.grey[600]}`,
	borderRadius: '50%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	cursor: 'pointer',
	width: '6rem',
	height: '6rem',
	alignItems: 'center',
}));

export const MainGraphWraper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(1.5),
	backgroundColor: theme.palette.common.white,
	border: `1px solid ${theme.palette.secondary.main}`,
	borderRadius: '4px',
	height: '25rem',
	position: 'relative',
	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(1),
	},
}));
export const SourceStyle = styled(Typography)(() => ({
	position: 'absolute',
	bottom: '0.7rem',
	right: '1rem',
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	border: '1.8px solid',
	borderColor: theme.palette.primary.main,
	borderRadius: '4px',
	'&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
}));

export const StyledGalleryImg = styled('img')(({ theme }) => ({
	borderRadius: '4px',
	marginRight: theme.spacing(1),
	width: '60px',
	height: '60px',
}));

export const TableBackground = styled(Box)(({ theme }) => ({
	background: theme.palette.common.white,
	height: 'auto',
	borderRadius: '8px',
	maxWidth: '1400px',
	margin: 'auto',
	width: '100%',
	overflowX: 'hidden',
}));

export const TableWrapper = styled(Box)(() => ({
	height: '700px',
}));

export const FilterSelectStyle = styled(Select)(({ theme }) => ({
	borderRadius: '4px',
	backgroundColor: theme.palette.primary.light,
	fontSize: '12px',
	minWidth: '4rem',
	fontWeight: 500,
	color: theme.palette.primary.main,
	'.MuiSvgIcon-root ': {
		fill: `${theme.palette.primary.main}  !important`,
	},
	'.MuiSelect-select': {
		padding: '0.5rem 0.6rem',
	},
}));

export const MainFilterSelectStyle = styled(Select)<CustomSelectProps>(({ theme,hasoptionselected }) => ({
	border: 'none',
	fontSize: '14px',
	color: theme.palette.primary.main,
	backgroundColor: hasoptionselected ? theme.palette.primary.light : theme.palette.primary.lighter,
	fontWeight: 500,
	marginRight: theme.spacing(1.5),
	'.MuiSvgIcon-root ': {
		fill: `${theme.palette.primary.main}  !important`,
	},
	'.MuiSelect-select': {
		padding: theme.spacing(1),
	},
	[theme.breakpoints.down('sm')]: {
		marginRight: theme.spacing(0),
		marginBottom: theme.spacing(1.5),
	},
}));

export const LoginWrapper = styled(Box)(({ theme }) => ({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	paddingBottom: theme.spacing(3),
}));

export const ErrorWrapper = styled(Box)(({ theme }) => ({
	marginBottom: theme.spacing(3),
}));

export const EmailWrapper = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.primary.light,
	padding: theme.spacing(2),
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginRight: theme.spacing(1),
}));

export const MainWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	height: '100%',
	[theme.breakpoints.down('md')]: {
		height: '70%',
	},
}));

export const ResetButton = styled(Button)(({ theme }) => ({
	padding: '0px',
	textDecoration: 'underline',
	textDecorationColor: theme.palette.primary.main,
}));

export const PhoneInputWrapper = styled(Box)(({ theme }) => ({
	borderRadius: '7px',
	padding: theme.spacing(2.11, 0),
}));

export const FlexWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	margin: 'auto',
	marginTop: theme.spacing(4),
	cursor: 'pointer',
}));

export const CaptchaWrapper = styled(Box)(({ theme }) => ({
	margin: theme.spacing(1, 0),
}));

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'white',
		border: '1px solid',
		borderColor: theme.palette.primary.main,
		fontSize: 11,
		width: '230px',
		padding: theme.spacing(2),
	},
}));

export const Circle = styled(CircleIcon)(() => ({
	fontSize: '8px',
	color: 'black',
}));

export const FlexWrap = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	marginBottom: theme.spacing(0.5),
}));

export const UnderlineText = styled(Typography)(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.primary.main}`,
	cursor: 'pointer',
	width: 'fit-content',
}));

export const Phone = styled(PhoneIcon)(({ theme }) => ({
	color: 'white',
	fontSize: '13px',
	marginRight: theme.spacing(1),
}));

export const Mail = styled(MailOutlineIcon)(({ theme }) => ({
	color: 'white',
	fontSize: '13px',
	marginRight: theme.spacing(1),
}));

export const Location = styled(PlaceIcon)(({ theme }) => ({
	color: 'white',
	fontSize: '13px',
	marginRight: theme.spacing(1),
}));

export const WhiteBgWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	borderRadius: '4px',
	width: '100%',
	border: `1px solid ${theme.palette.secondary.main}`,
	background: theme.palette.common.white,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(1.5),
	},
}));

export const XSBtn = styled(Typography)(({ theme }) => ({
	fontSize: '13px',
	fontWeight: 500,
	borderBottom: `1px solid ${theme.palette.primary.main}`,
	cursor: 'pointer',
}));

export const BrowseButton = styled(Button)(({ theme }) => ({
	minHeight: '106px',
	border: `1px dashed ${theme.palette.primary.main}`,
	textTransform: 'none',
}));

export const ImageContainer = styled(Grid)(({ theme }) => ({
	position: 'relative',
	padding: theme.spacing(0.5, 0),
}));

export const ImageWrapper = styled(Grid)(({ theme }) => ({
	marginTop: theme.spacing(1),
}));

export const PreviewImg = styled('img')(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	borderRadius: '4px',
	width: '90%',
	objectFit: 'cover',
	height: '100px',
	cursor: 'pointer',
}));

export const PreviewWrapper = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	borderRadius: '4px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '120px',
	height: '100px',
}));

export const CancelButton = styled(CancelIcon)(({ theme }) => ({
	position: 'absolute',
	right: '4%',
	top: '-5px',
	color: theme.palette.grey[900],
	cursor: 'pointer',
	[theme.breakpoints.down('lg')]: {
		right: '6%',
	},
	[theme.breakpoints.down('sm')]: {
		right: '1px',
	},
}));

export const AddMoreButton = styled(Button)(({ theme }) => ({
	height: '100px !important',
	border: `1px dashed ${theme.palette.primary.main}`,
	textTransform: 'none',
	display: 'flex',
	alignItems: 'center',
	paddingLeft: '0px',
	paddingRight: '0px',
	width: '90%',
}));

export const AttachIcon = styled(AttachFileIcon)(() => ({
	fontSize: '15px',
}));

export const AlertButtonWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	margin: theme.spacing(1.5, 0),
}));

export const CancelAlertButton = styled(Button)(({ theme }) => ({
	marginRight: theme.spacing(2),
	width: '150px',
	color: theme.palette.grey[100],
	fontWeight: 700,
	lineHeight: '28px',
}));

export const CreateAlertButton = styled(LoadingButton)(() => ({
	minWidth: '150px',
	textTransform: 'none',
}));

export const CreateAlertFlexWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
}));

export const AlertMainWrapper = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	padding: theme.spacing(1.5),
	marginTop: theme.spacing(1.5),
	borderRadius: '4px',
	minHeight: '75vh',
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(1),
	},
}));

export const WhiteCard = styled(Box)(({ theme }) => ({
	padding: theme.spacing(1.5),
	borderRadius: '4px',
	width: '100%',
	border: `1px solid ${theme.palette.secondary.main}`,
	background: theme.palette.common.white,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(1),
	},
}));

export const CreateButton = styled(Button)(({ theme }) => ({
	minWidth: '230px',
	[theme.breakpoints.down('md')]: {
		width: 'unset',
	},
}));

export const EmptyWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '5rem',
	[theme.breakpoints.down('md')]: {
		height: '4rem',
	},
}));

export const DownloadButton = styled(Button)(({ theme }) => ({
	color: theme.palette.grey[900],
	padding: theme.spacing(0.5),
	minWidth: '130px',
	[theme.breakpoints.down('md')]: {
		padding: '0px',
	},
}));

export const Cell = styled(TableCell)(({ theme }) => ({
	borderRadius: '0px !important',
	boxShadow: 'none !important',
	color: theme.palette.grey[900],
	fontSize: '15px',
	fontWeight: 600,
	lineHeight: '18px',
	backgroundColor: `${theme.palette.secondary.main} !important`,
	backgroundImage: 'none !important',
	padding: theme.spacing(1.5),
}));

export const BodyCell = styled(TableCell)(({ theme }) => ({
	borderRadius: '0px !important',
	boxShadow: 'none !important',
	color: theme.palette.grey[900],
	fontSize: '15px',
	fontWeight: 600,
	lineHeight: '18px',
	backgroundColor: `white !important`,
	backgroundImage: 'none !important',
	minWidth: '100px',
	borderBottom: `1px solid ${theme.palette.secondary.main}`,
}));

export const BorderedRightCell = styled(TableCell)(({ theme }) => ({
	borderRadius: '0px 8px 0px 0px !important',
	boxShadow: 'none !important',
	color: theme.palette.grey[900],
	fontSize: '15px',
	fontWeight: 600,
	lineHeight: '18px',
	backgroundColor: `${theme.palette.secondary.main} !important`,
	backgroundImage: 'none !important',
	padding: theme.spacing(1.5),
}));

export const CustomHeader = styled(TableHead)(({ theme }) => ({
	borderRadius: '0px 8px 0px 0px !important',
	marginBottom: `${theme.spacing(1.5)} !important`,
}));

export const CustomBody = styled(TableBody)(({ theme }) => ({
	border: `1px solid ${theme.palette.secondary.main}`,
	margin: `${theme.spacing(1.5)} !important`,
	padding: `${theme.spacing(1.5)} !important`,
	borderRadius: '0px !important',
}));

export const OptionWrapper = styled(Box)(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.grey[200]}`,
	marginBottom: theme.spacing(3),
}));

export const PaginateButton = styled(Button)(({ theme }) => ({
	minWidth: '40px',
	padding: '0px',
}));

export const AutoCompleteTextField = styled(TextField)<CustomTextFieldProps>(({ theme,hasoptionselected  }) => ({
	'& .MuiFilledInput-root': {
		backgroundColor: hasoptionselected ? theme.palette.primary.light : theme.palette.primary.lighter,
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
		padding: '0px',

		'&.MuiFilledInput-input': {
			padding: '7px 6px',
		}
	},
}));

export const DateButton = styled(Button)<CustomButtonProps>(({ theme,hasoptionselected  }) => ({
	backgroundColor: hasoptionselected ? theme.palette.primary.light : theme.palette.primary.lighter,
	color: hasoptionselected ? theme.palette.primary.main : theme.palette.grey[100],
	fontSize: '14px',
	fontWeight: 500,
	lineHeight: '18px',
	borderRadius: '8px',
	height: '38px',
	marginRight: theme.spacing(1.5),
	padding: '0px 8px',
	[theme.breakpoints.down('sm')]: {
		marginRight: '0px'
	},
}));

export const TickIcon = styled(DoneIcon)(({ theme }) => ({
	marginRight: theme.spacing(1),
}));

export const ToggleText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 500,
	lineHeight: '18px',
}));
