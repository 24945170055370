import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// slices
import alertReducer from 'src/common/redux/slices/alert';
import alertFilterReducer from 'src/common/redux/slices/alertFilter';
import authReducer from 'src/common/redux/slices/auth';
import contractDetailsReducer from 'src/common/redux/slices/contractDetails';
import dateReducer from 'src/common/redux/slices/date';
import incidentReducer from 'src/common/redux/slices/incident';
import incidentFilterReducer from 'src/common/redux/slices/incidentFilter';
import locationReducer from 'src/common/redux/slices/location';
import nepalReducer from 'src/common/redux/slices/nepal';
import waterBodyReducer from 'src/common/redux/slices/waterBody';

// ----------------------------------------------------------------------

const rootPersistConfig = {
	key: 'root',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
};

const alertConfig = {
	key: 'alert',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const alertFilterConfig = {
	key: 'alertFilter',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const authConfig = {
	key: 'auth',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};
const contractDetailsConfig = {
	key: 'contractDetails',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const dateConfig = {
	key: 'date',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const incidentConfig = {
	key: 'incident',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const incidentFilterConfig = {
	key: 'incidentFilter',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const locationConfig = {
	key: 'location',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const nepalConfig = {
	key: 'nepal',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const waterBodyConfig = {
	key: 'waterBody',
	storage,
	keyPrefix: 'redux-',
	whitelist: ['value'],
};

const rootReducer = combineReducers({
	alert: persistReducer(alertConfig, alertReducer),
	alertFilter: persistReducer(alertFilterConfig, alertFilterReducer),
	auth: persistReducer(authConfig, authReducer),
	contractDetails: persistReducer(contractDetailsConfig, contractDetailsReducer),
	date: persistReducer(dateConfig, dateReducer),
	incident: persistReducer(incidentConfig, incidentReducer),
	incidentFilter: persistReducer(incidentFilterConfig, incidentFilterReducer),
	location: persistReducer(locationConfig, locationReducer),
	nepal: persistReducer(nepalConfig, nepalReducer),
	waterBody: persistReducer(waterBodyConfig, waterBodyReducer),
});

export { rootPersistConfig, rootReducer };
