import { GOOGLE_ANALYTICS_KEY_PROD,GOOGLE_ANALYTICS_KEY_TEST } from 'src/common/utils/constants';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeProvider from './theme';
import ReactGA from 'react-ga4';
import Router from './routes';
// ----------------------------------------------------------------------

ReactGA.initialize(window.location.origin.includes('befiqr') ? GOOGLE_ANALYTICS_KEY_PROD : GOOGLE_ANALYTICS_KEY_TEST);

export default function App() {

	return (
		<ThemeProvider>
			<ThemeColorPresets>
				<NotistackProvider>
					<MotionLazyContainer>
						<ProgressBarStyle />
						<ScrollToTop />
						<Router />
					</MotionLazyContainer>
				</NotistackProvider>
			</ThemeColorPresets>
		</ThemeProvider>
	);
}
