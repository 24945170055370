import jwtDecode from "jwt-decode";

//
import axios from "src/common/utils/axios";

// ----------------------------------------------------------------------

const isValidToken = (api_token: string) => {
    if (!api_token) {
        return false;
    }
    const decoded = jwtDecode<{ exp: number }>(api_token);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp: any) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

const setSession = (api_token: string | null) => {
    if (api_token) {
        localStorage.setItem("api_token", api_token);
        axios.defaults.headers.common.Authorization = `Bearer ${api_token}`;
        // This function below will handle when token is expired
        // const { exp } = jwtDecode(api_token);
        // handleTokenExpired(exp);
    } else {
        localStorage.removeItem("api_token");
        delete axios.defaults.headers.common.Authorization;
    }
};

const setNewUser = (new_user: boolean) => {
    localStorage.setItem("new_user", new_user ? '1' : '0');
};

export { isValidToken, setNewUser, setSession };
