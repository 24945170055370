import { DOMAIN_PATHS } from 'src/routes/paths';
import SvgIconStyle from 'src/components/SvgIconStyle';
// ----------------------------------------------------------------------

export const getIcon = (name: string) => (
	<SvgIconStyle
		src={
			window.location.origin.includes('localhost') ? `/icons/${name}.svg` : `/app/icons/${name}.svg`
		}
		sx={{ width: 1, height: 1 }}
	/>
);

const ICONS = {
	alert: getIcon('alert'),
	dashboard: getIcon('dashboard'),
	drilldown: getIcon('drilldown'),
	incident: getIcon('incident'),
	profile: getIcon('profile'),
	state: getIcon('state'),
};

const navConfig = [
	{
		subheader: '',
		items: [
			{ title: 'Dashboard', path: DOMAIN_PATHS.overview, icon: ICONS.dashboard },
			{
				title: 'DataDrill-Down',
				path: DOMAIN_PATHS.dataDrilldown,
				icon: ICONS.drilldown,
				children: [
					{ title: 'DepartmentJurisdiction', path: DOMAIN_PATHS.departmentJurisdiction },
					// { title: 'DistrictJurisdiction', path: DOMAIN_PATHS.districtJurisdiction },
					{ title: 'Rivers&Assets', path: DOMAIN_PATHS.riverAssets },
				],
			},
			{
				title: 'AlertMgmt',
				path: DOMAIN_PATHS.alerts,
				icon: ICONS.alert,
			},
			{
				title: 'IncidentMgmt',
				path: DOMAIN_PATHS.incidentManagement,
				icon: ICONS.incident,
			},

			{ title: 'Profile', path: DOMAIN_PATHS.profile, icon: ICONS.profile },
		],
	},
];

export const navConfigHigherOfficials = [
	{
		subheader: '',
		items: [
			{ title: 'Dashboard', path: DOMAIN_PATHS.overview, icon: ICONS.dashboard },
			{
				title: 'StateView',
				path: DOMAIN_PATHS.stateView,
				icon: ICONS.state,
				children: [
					{
						title: 'GraphicalView',
						path: '',
						children: [
							{ title: 'StateBarrages', path: DOMAIN_PATHS.graphBarrages },
							{ title: 'StateRivers', path: DOMAIN_PATHS.rivers },
							{ title: 'DistrictRainfall', path: DOMAIN_PATHS.districtRainfall },
							{ title: 'BlockRainfall', path: DOMAIN_PATHS.blockRainfall },
							{ title: 'RainfallPrediction', path: DOMAIN_PATHS.rainfallPrediction },
						],
					},
					{
						title: 'TableView',
						path: '',
						children: [
							{ title: 'RtdasStations', path: DOMAIN_PATHS.rtdasStations },
							{ title: 'StateBarrages', path: DOMAIN_PATHS.tabularBarrages },
							{ title: 'CWCStation', path: DOMAIN_PATHS.cwcStations },
							{ title: 'BmskRainfall', path: DOMAIN_PATHS.bmskRainfall },
							{ title: 'NepalRainfall', path: DOMAIN_PATHS.nepalRainfall },
						],
					},
				],
			},
			{
				title: 'DataDrill-Down',
				path: DOMAIN_PATHS.dataDrilldown,
				icon: ICONS.drilldown,
				children: [
					{ title: 'DepartmentJurisdiction', path: DOMAIN_PATHS.departmentJurisdiction },
					// { title: 'DistrictJurisdiction', path: DOMAIN_PATHS.districtJurisdiction },
					{ title: 'Rivers&Assets', path: DOMAIN_PATHS.riverAssets },
				],
			},
			{
				title: 'AlertMgmt',
				path: DOMAIN_PATHS.alerts,
				icon: ICONS.alert,
			},
			{
				title: 'IncidentMgmt',
				path: DOMAIN_PATHS.incidentManagement,
				icon: ICONS.incident,
			},

			{ title: 'Profile', path: DOMAIN_PATHS.profile, icon: ICONS.profile },
		],
	},
];

export default navConfig;
