import { createSlice } from '@reduxjs/toolkit';

export const locationSlice = createSlice({
	name: 'location',
	initialState: {
		value: {
			districtId: '',
			districtName: '',
			blockId: '',
			blockName: '',
			riverId: '',
			riverName: '',
			zoneId: '',
			zoneName: '',
			circleId: '',
			circleName: '',
			divisionId: '',
			divisionName: '',
			subDivisionId: '',
			subDivisionName: '',
			refreshLocation: false,
		},
	},
	reducers: {
		locationDetails: (state, action) => {
			state.value = action.payload;
		},
	},
});
export const { locationDetails } = locationSlice.actions;

export default locationSlice.reducer;
