import { useEffect } from 'react';

import { Box, Drawer, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

import CollapseButton from './CollapseButton';
import cssStyles from 'src/common/utils/cssStyles';
import { IconButtonAnimate } from 'src/components/animate';
import { NAVBAR } from 'src/config';
import navConfig, { navConfigHigherOfficials } from './NavConfig';
import { NavSectionVertical } from 'src/components/nav-section';
import Scrollbar from 'src/components/Scrollbar';
import useCollapseDrawer from 'src/common/hooks/useCollapseDrawer';
import useResponsive from 'src/common/hooks/useResponsive';
import WrdLogo from 'src/assets/images/WrdLogo.png';
import WrdWhiteLogo from 'src/assets/images/WrdLogoWhite.png';
import useAuth from 'src/common/hooks/useAuth';
import { CE, HIGHER_OFFICIALS } from 'src/common/utils/constants';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('lg')]: {
		flexShrink: 0,
		transition: theme.transitions.create('width', {
			duration: theme.transitions.duration.shorter,
		}),
	},
}));

// ----------------------------------------------------------------------

type SideNavProps = {
	isOpenSidebar: boolean;
	onCloseSidebar: VoidFunction;
};

const Img = styled('img')(() => ({
	width: '100%',
	objectFit: 'cover',
}));

const CollapseImg = styled('img')(() => ({
	height: '40px',
	width: '100%',
	objectFit: 'cover',
}));

const IconStyle = styled(CloseIcon)(() => ({
	color: '#fff',
}));

export default function SideNav({ isOpenSidebar, onCloseSidebar }: SideNavProps) {
	const theme = useTheme();

	const { pathname } = useLocation();

	const isDesktop = useResponsive('up', 'lg');

	const { isCollapse, collapseClick, collapseHover, onToggleCollapse } = useCollapseDrawer();

	const { user } = useAuth();

	useEffect(() => {
		if (isOpenSidebar) {
			onCloseSidebar();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const renderContent = (
		<>
			<Stack
				spacing={3}
				sx={{
					pt: isDesktop && isCollapse ? 2 : 1,
					px: isDesktop && isCollapse ? 1 : 3,
					mb: isDesktop && isCollapse ? 4 : 2,
					flexShrink: 0,
					// Change color here for logo bg
					background: '#2565AE',
					height: '70px',
					...(isCollapse && { alignItems: 'center' }),
				}}
			>
				<Stack
					direction={isCollapse ? 'column' : 'row'}
					alignItems="center"
					justifyContent="center"
				>
					{isDesktop && !isCollapse && <Img src={String(WrdWhiteLogo)} />}

					{isDesktop && isCollapse && <CollapseImg src={String(WrdLogo)} />}

					{isDesktop && !isCollapse && (
						<CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
					)}

					{isCollapse && isDesktop && (
						<CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
					)}
					{!isCollapse && !isDesktop && (
						<>
							<Img src={String(WrdWhiteLogo)} />
							<IconButtonAnimate onClick={() => onCloseSidebar()}>
								<IconStyle />
							</IconButtonAnimate>
						</>
					)}
				</Stack>
			</Stack>
			<Scrollbar
				sx={{
					height: 1,
					'& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
				}}
			>
				<NavSectionVertical
					navConfig={
						(user &&
							(user.account_type === HIGHER_OFFICIALS ||
								(user.designation && user.designation.label === CE)) &&
							navConfigHigherOfficials) ||
						navConfig
					}
					isCollapse={isCollapse}
				/>

				<Box sx={{ flexGrow: 1 }} />
			</Scrollbar>
		</>
	);

	return (
		<RootStyle
			sx={{
				width: {
					lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
				},
				...(collapseClick && {
					position: 'absolute',
				}),
			}}
		>
			{!isDesktop && (
				<Drawer
					hideBackdrop
					open={isOpenSidebar}
					onClose={onCloseSidebar}
					sx={{ background: 'rgba(0,0,0,0.7)' }}
					PaperProps={{
						sx: {
							width: NAVBAR.DASHBOARD_WIDTH,
							borderRightStyle: 'none',
							bgcolor: '#2565AE',
							color: '#fff',
						},
					}}
				>
					{renderContent}
				</Drawer>
			)}

			{isDesktop && (
				<Drawer
					open
					variant="persistent"
					PaperProps={{
						sx: {
							width: NAVBAR.DASHBOARD_WIDTH,
							borderRightStyle: 'none',
							bgcolor: '#2565AE',
							color: '#FAFAFA',
							transition: (theme) =>
								theme.transitions.create('width', {
									duration: theme.transitions.duration.standard,
								}),
							...(isCollapse && {
								width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
							}),
							...(collapseHover && {
								...cssStyles(theme).bgBlur(),
								boxShadow: (theme) => theme.customShadows.z24,
							}),
						},
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</RootStyle>
	);
}
