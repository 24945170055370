import { forwardRef, ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import ReactGA from 'react-ga4';
// @mui
import { Box, BoxProps, styled, Typography } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
	children: ReactNode;
	meta?: ReactNode;
	title: string;
	headerTitle?: string;
	backFlag?: boolean;
	backFunc?: () => void;
	removePadding?: boolean;
}

const HeaderWrapper = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: '1.4rem',
	zIndex: theme.zIndex.appBar + 4,
	display: 'flex',
	alignItems: 'center',
	[theme.breakpoints.down('lg')]: {
		position: 'unset',
		paddingBottom: theme.spacing(1),
	},
}));

const FontStyle = styled(Typography)(({ theme }) => ({
	color: theme.palette.common.white,
	[theme.breakpoints.down('lg')]: {
		color: theme.palette.common.black,
	},
}));

const BackButton = styled(KeyboardArrowLeftOutlinedIcon)(({ theme }) => ({
	cursor: 'pointer',
	color: theme.palette.common.white,
	[theme.breakpoints.down('lg')]: {
		color: theme.palette.common.black,
	},
}));

const Page = forwardRef<HTMLDivElement, Props>(
	(
		{ children, headerTitle, backFlag, backFunc, removePadding, title = '', meta, ...other },
		ref
	) => {

		useEffect(()=>{
			ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
		},[])

		return <>
			<Helmet>
				<title>{`${title} | FMISC`}</title>
				{meta}
			</Helmet>

			<Box ref={ref} {...other}>
				<HeaderWrapper sx={{ paddingBottom: removePadding ? '0px !important' : 'unset' }}>
					{backFlag && <BackButton onClick={backFunc} />}
					<FontStyle variant="h6" pl={0.4}>
						{headerTitle}
					</FontStyle>
				</HeaderWrapper>
				{children}
			</Box>
		</>
	}
	
);

export default Page;
