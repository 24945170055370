import { createSlice } from '@reduxjs/toolkit';

export const dateSlice = createSlice({
  name: 'date',
  initialState: {
    value: {
        date:'',
    },
  },
  reducers: {
    dateDetails: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { dateDetails } = dateSlice.actions;

export default dateSlice.reducer;
