import { ElementType, lazy, Suspense } from 'react';

import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import AuthGuard from 'src/common/guards/AuthGuard';
import { DOMAIN_PATHS } from './paths';
import GuestGuard from 'src/common/guards/GuestGuard';
import LoadingScreen from '../components/LoadingScreen';
import LogoOnlyLayout from '../theme/layouts/LogoOnlyLayout';
import MainLayout from '../theme/layouts/dashboard';

const Loadable = (Component: ElementType) => (props: any) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation();
	return (
		<Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
			<Component {...props} />
		</Suspense>
	);
};

export default function Router() {
	return useRoutes([
		{
			path: '',
			element: (
				<AuthGuard>
					<MainLayout />
				</AuthGuard>
			),
			children: [
				{ element: <Navigate to={DOMAIN_PATHS.overview} replace />, index: true },
				{ path: 'dashboard', element: <Dashboard /> },
				{ path: 'incident', element: <PageIncident /> },
				{ path: 'create-incidents', element: <CreateIncidentPage /> },
				{ path: 'view-incidents', element: <ViewIncidentPage /> },
				{ path: 'profile', element: <PageUserProfile /> },
				{ path: 'alerts', element: <AlertsPage /> },
				{ path: 'create-alerts', element: <CreateAlertsPage /> },
				{ path: 'view-alerts', element: <ViewAlertsPage /> },
				{ path: 'close-alerts', element: <CloseAlertsPage /> },
				{
					path: 'data-drilldown',
					children: [
						{ path: 'department-jurisdiction', element: <PageDepartmentJurisdiction /> },
						{ path: 'district-jurisdiction', element: <PageDistrictJurisdiction /> },
						{ path: 'river-and-assets', element: <PageRiverAndAssets /> },
					],
				},
				{
					path: 'state-view',
					children: [
						{ path: 'block-rainfall', element: <PageBlockRainfall /> },
						{ path: 'bmsk-rainfall', element: <PageBmskRainfall /> },
						{ path: 'cwc-stations', element: <PageCwcStations /> },
						{ path: 'district-rainfall', element: <PageDistrictRainfall /> },
						{ path: 'graphical-barrages', element: <PageGraphicalBarrages /> },
						{ path: 'nepal-rainfall', element: <PageNepalRainfall /> },
						{ path: 'rainfall-prediction', element: <PageRainfallPrediction /> },
						{ path: 'rivers', element: <PageRivers /> },
						{ path: 'rtdas-stations', element: <PageRtdasStations /> },
						{ path: 'tabular-barrages', element: <PageTabularBarrages /> },
					],
				},
				// { path: 'rainfall-details', element: <PageRainfallDetails /> },
				{ path: 'rainfall-details', element: <RainfallIframeDetailsPage /> },
				// { path: 'waterlevel-details', element: <PageWaterLevelDetails /> },
				{ path: 'waterlevel-details', element: <PageWaterLevelDetails /> },
				{ path: 'nepal-rainfall-watch', element: <PageNepalNews /> },
			],
		},
		{
			path: 'preference',
			element: (
				<AuthGuard>
					<PagePreference />
				</AuthGuard>
			),
		},
		{
			path: 'auth',
			children: [
				{
					path: '',
					element: (
						<GuestGuard>
							<PageLoginPhone />
						</GuestGuard>
					),
				},
				{
					path: 'otp',
					element: (
						<GuestGuard>
							<PageLoginOTP />
						</GuestGuard>
					),
				},
				{
					path: 'resetPasswordLink',
					element: (
						<GuestGuard>
							<PageResetPasswordLink />
						</GuestGuard>
					),
				},
				{
					path: 'profileDetails',
					element: (
						<GuestGuard>
							<PageProfileDetails />
						</GuestGuard>
					),
				},
				{
					path: 'status',
					element: (
						<GuestGuard>
							<PageStatus />
						</GuestGuard>
					),
				},
				{
					path: 'reset-password',
					element: (
						<GuestGuard>
							<PageResetPassword />
						</GuestGuard>
					),
				},
				{
					path: 'verify',
					element: (
						<GuestGuard>
							<PageCreatePassword />
						</GuestGuard>
					),
				},
			],
		},
		{
			path: 'register',
			children: [
				{
					path: 'contractSignup',
					element: (
						<GuestGuard>
							<PageContractSignup />
						</GuestGuard>
					),
				},
				{
					path: 'generalDetails',
					element: (
						<GuestGuard>
							<PageContractGeneralDetailsSignup />
						</GuestGuard>
					),
				},
				{
					path: 'jurisdictionDetails',
					element: (
						<GuestGuard>
							<PageContractJurisdictionDetails />
						</GuestGuard>
					),
				},
			],
		},
		{
			path: '*',
			element: <LogoOnlyLayout />,
			children: [
				{ path: '404', element: <NotFound /> },
				{ path: '*', element: <Navigate to="/404" replace /> },
			],
		},
		{ path: 'privacy-policy', element: <PrivacyPolicy /> },
		{ path: 'iframe/barrage', element: <BarrageIframe /> },
		{ path: 'iframe/rtdas', element: <RTDASIframe /> },
		{ path: 'iframe/cwc', element: <CWCIFRAME /> },
		{ path: 'terms-and-conditions', element: <TermsConditions /> },
		{ path: 'copyright-policy', element: <CopyrightPolicy /> },
		{ path: 'disclaimer', element: <Disclaimer /> },
		{ path: '*', element: <Navigate to="/404" replace /> },
		{
			path: '/',
			element: <Navigate to={DOMAIN_PATHS.overview} replace />,
		},
	]);
}

// Dashboard
const Dashboard = Loadable(lazy(() => import('src/pages/domain/dashboard/DashboardPage')));
// const PageRainfallDetails = Loadable(
// 	lazy(() => import('src/pages/domain/dashboard/details/RainfallDetailsPage'))
// );
const RainfallIframeDetailsPage = Loadable(
	lazy(() => import('src/pages/domain/dashboard/details/RainfallIframeDetailsPage'))
);
// const PageWaterLevelDetails = Loadable(
// 	lazy(() => import('src/pages/domain/dashboard/details/WaterDetailsPage'))
// );

const PageWaterLevelDetails = Loadable(
	lazy(() => import('src/pages/domain/dashboard/details/WaterLevelDetailsPage'))
);
const PageIncident = Loadable(lazy(() => import('src/pages/domain/incidents/IncidentPage')));
const CreateIncidentPage = Loadable(
	lazy(() => import('src/pages/domain/incidents/CreateIncidentPage'))
);
const ViewIncidentPage = Loadable(
	lazy(() => import('src/pages/domain/incidents/ViewIncidentPage'))
);
const PageUserProfile = Loadable(lazy(() => import('src/pages/account/Profile')));
const PageDistrictJurisdiction = Loadable(
	lazy(() => import('src/pages/domain/data-drilldown/DistrictJurisdiction'))
);
const PageDepartmentJurisdiction = Loadable(
	lazy(() => import('src/pages/domain/data-drilldown/DepartmentJurisdiction'))
);
const PageRiverAndAssets = Loadable(
	lazy(() => import('src/pages/domain/data-drilldown/RiverAndAssets'))
);
const AlertsPage = Loadable(lazy(() => import('src/pages/domain/alerts/AlertsPage')));
const CloseAlertsPage = Loadable(lazy(() => import('src/pages/domain/alerts/CloseAlertPage')));
const CreateAlertsPage = Loadable(lazy(() => import('src/pages/domain/alerts/CreateAlertPage')));
const ViewAlertsPage = Loadable(lazy(() => import('src/pages/domain/alerts/ViewAlertPage')));
const NotFound = Loadable(lazy(() => import('src/pages/error/Page404')));

const PageBlockRainfall = Loadable(
	lazy(() => import('src/pages/domain/state-view/graphical-view/BlockRainfallPage'))
);
const PageDistrictRainfall = Loadable(
	lazy(() => import('src/pages/domain/state-view/graphical-view/DistrictRainfallPage'))
);
const PageGraphicalBarrages = Loadable(
	lazy(() => import('src/pages/domain/state-view/graphical-view/BarragesPage'))
);
const PageRainfallPrediction = Loadable(
	lazy(() => import('src/pages/domain/state-view/graphical-view/RainfallPredictionPage'))
);
const PageRivers = Loadable(
	lazy(() => import('src/pages/domain/state-view/graphical-view/RiversPage'))
);

const PageBmskRainfall = Loadable(
	lazy(() => import('src/pages/domain/state-view/table-view/BmskRainfallPage'))
);
const PageCwcStations = Loadable(
	lazy(() => import('src/pages/domain/state-view/table-view/CwcStationsPage'))
);
const PageNepalRainfall = Loadable(
	lazy(() => import('src/pages/domain/state-view/table-view/NepalRainfallPage'))
);
const PageRtdasStations = Loadable(
	lazy(() => import('src/pages/domain/state-view/table-view/RtdasStationsPage'))
);
const PageTabularBarrages = Loadable(
	lazy(() => import('src/pages/domain/state-view/table-view/BarragesPage'))
);

const PageNepalNews = Loadable(
	lazy(() => import('src/pages/domain/dashboard/details/NepalWatchDetailsPage'))
);
const PrivacyPolicy = Loadable(lazy(() => import('src/pages/auth/PrivacyPolicyPage')));
const TermsConditions = Loadable(lazy(() => import('src/pages/auth/TermsPage')));
const CopyrightPolicy = Loadable(lazy(() => import('src/pages/auth/CopyrightPolicyPage')));
const Disclaimer = Loadable(lazy(() => import('src/pages/auth/DisclaimerPage')));
// Authentication
const PageLoginPhone = Loadable(lazy(() => import('src/pages/auth/Login')));
const PageLoginOTP = Loadable(lazy(() => import('src/pages/auth/LoginOTP')));
const PageResetPasswordLink = Loadable(lazy(() => import('src/pages/auth/ResetPasswordLink')));
const PageResetPassword = Loadable(lazy(() => import('src/pages/auth/ResetPassword')));
const PageCreatePassword = Loadable(
	lazy(() => import('src/pages/auth/signup/permanent/CreatePassword'))
);
const PageProfileDetails = Loadable(
	lazy(() => import('src/pages/auth/signup/permanent/ProfileDetails'))
);
const PageContractSignup = Loadable(lazy(() => import('src/pages/auth/signup/contract/Signup')));
const PageContractGeneralDetailsSignup = Loadable(
	lazy(() => import('src/pages/auth/signup/contract/GeneralDetails'))
);
const PageContractJurisdictionDetails = Loadable(
	lazy(() => import('src/pages/auth/signup/contract/JurisdictionDetails'))
);
const PageStatus = Loadable(lazy(() => import('src/pages/auth/StatusPage')));

const PagePreference = Loadable(lazy(() => import('src/pages/domain/PreferencePage')));

const BarrageIframe = Loadable(lazy(() => import('src/pages/iframe/barrage')));
const RTDASIframe = Loadable(lazy(() => import('src/pages/iframe/rtdas')));
const CWCIFRAME = Loadable(lazy(() => import('src/pages/iframe/cwc')));
