import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Datepicker(theme: Theme) {
	return {
		MuiCalendarPicker: {
			styleOverrides: {
				root: {
					background: theme.palette.primary.light,
					borderRadius: '4px',
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					background: theme.palette.primary.light,
				},
			},
		},
	};
}
