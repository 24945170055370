import { Box, List, Typography, Tooltip, Button, styled } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { AUTH_PATHS } from 'src/routes/paths';
import facebook from 'src/assets/images/Facebook.png';
import { getIcon } from 'src/theme/layouts/dashboard/navbar/NavConfig';
import instagram from 'src/assets/images/Instagram.png';
import { ListItemIconStyle } from 'src/components/nav-section/vertical/style';
import { locationDetails } from 'src/common/redux/slices/location';
import { NavListRoot } from 'src/components/nav-section/vertical/NavList';
import { NavSectionProps } from 'src/components/nav-section/type';
import twitter from 'src/assets/images/Twitter.png';
import useAuth from 'src/common/hooks/useAuth';
import { useDispatch } from 'src/common/redux/store';
import youtube from 'src/assets/images/YouTube.png';
import IrrigationLogo from 'src/assets/images/Irrigation-icon.png';
// import IrrigationLogo from 'src/assets/images/SVG/IrrigationLogo';
import axios from 'src/common/utils/axios';

const Container = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	marginLeft: theme.spacing(0.6),
	padding: theme.spacing(1, 1.5, 1, 2),
	color: theme.palette.common.white,
	cursor: 'pointer',
	height: '48px',
	'&:hover': {
		background: theme.palette.common.white,
		color: theme.palette.primary.main,
	},
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
	color: theme.palette.common.white,
	margin: theme.spacing(4, 1, 0, 1),
}));

const ContentInnerWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(1, 0),
	borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

const HelpDeskInnerWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(0, 0, 9, 0),
	borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

const Location = styled(FmdGoodOutlinedIcon)(() => ({
	color: 'white',
	fontSize: '15px',
}));

const ImgStyle = styled('img')(({ theme }) => ({
	marginRight: theme.spacing(1),
}));

const Phone = styled(LocalPhoneOutlinedIcon)(() => ({
	color: 'white',
	fontSize: '15px',
}));

const Mail = styled(MailOutlineIcon)(() => ({
	color: 'white',
	fontSize: '15px',
}));

const HelpWrapper = styled(Box)(({ theme }) => ({
	borderRadius: '4px',
	border: '1px solid',
	borderColor: theme.palette.common.white,
	padding: theme.spacing(2.5, 2, 1.5, 2),
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
	marginTop: theme.spacing(3),
}));

const LinkWrapper = styled(Box)(({ theme }) => ({
	borderRadius: '4px',
	border: '1px solid',
	borderColor: theme.palette.common.white,
	padding: theme.spacing(1, 4),
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginTop: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(0.5, 1),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'absolute',
	top: '-15%',
	backgroundColor: theme.palette.primary.main,
}));

const LinkButton = styled(Button)(() => ({
	color: 'white',
	lineHeight: 1.5,
	fontSize: '15px !important',
	fontWeight: '600',
	textTransform: 'none',
	height: '23px',
	padding: '0px',
}));

export default function NavSectionVertical({
	navConfig,
	isCollapse = false,
	...other
}: NavSectionProps) {
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const { logout } = useAuth();
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const ICONS = {
		logout: getIcon('logout'),
	};

	const handleLogout = async () => {
		try {
			dispatch(
				locationDetails({
					districtId: '',
					districtName: '',
					blockId: '',
					blockName: '',
					riverId: '',
					riverName: '',
					zoneId: '',
					zoneName: '',
					circleId: '',
					circleName: '',
					divisionId: '',
					divisionName: '',
					subDivisionId: '',
					subDivisionName: '',
					refreshLocation: false,
				})
			);
			await axios.get('/api/auth/logout');
			await logout();
			navigate(AUTH_PATHS.loginPhone, { replace: true });
		} catch (error) {
			enqueueSnackbar('Unable to logout!', { variant: 'error' });
		}
	};
	return (
		<>
			<Box {...other}>
				{navConfig.map((group) => (
					<List key={group.subheader} disablePadding sx={{ pl: 0.6 }}>
						{group.items.map((list) => (
							<NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
						))}
					</List>
				))}
			</Box>

			<Container onClick={handleLogout}>
				<Tooltip title={t(`common:Logout`)}>
					<ListItemIconStyle>{ICONS.logout}</ListItemIconStyle>
				</Tooltip>
				{!isCollapse && <Typography fontWeight={500}>{t(`common:Logout`)}</Typography>}
			</Container>
			{!isCollapse && (
				<ContentWrapper>
					<ContentInnerWrapper mb={6} borderTop="1px solid #7A7D81">
						<a
							href="https://irrigation.befiqr.in/state/graphical/gandak-barrage?platform=mobile"
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: 'none' }}
						>
							<Box display="flex" alignItems="center" px={1.5}>
								<img src={String(IrrigationLogo)} alt="insta-logo" width="45px" />
								<Typography variant="body1" fontWeight={700} color="white" ml={1}>
									{t('common:irrigation')}
								</Typography>
							</Box>
						</a>
					</ContentInnerWrapper>
					<ContentInnerWrapper>
						<Typography variant="body1" fontWeight={700}>
							{t('common:FMISCHelpdesk')}
						</Typography>
					</ContentInnerWrapper>
					<HelpDeskInnerWrapper>
						<HelpWrapper>
							<LinkButton variant="text" href="tel:18003456145">
								1800 3456 145
							</LinkButton>
							<LinkButton variant="text" href="tel:06122256999">
								0612-2256999
							</LinkButton>
							<IconWrapper>
								<Phone />
							</IconWrapper>
						</HelpWrapper>
						<HelpWrapper>
							<LinkButton variant="text" href="mailto:fmiscbihar@gmail.com">
								fmiscbihar@gmail.com
							</LinkButton>
							<LinkButton variant="text" href="mailto:fmisc.pat-bih@gov.in">
								fmisc.pat-bih@gov.in
							</LinkButton>
							<IconWrapper>
								<Mail />
							</IconWrapper>
						</HelpWrapper>
						<HelpWrapper>
							<Typography fontSize="12px" fontWeight={500} lineHeight="15px" textAlign="center">
								2nd Floor, Jal Sansadhan Bhawan, Anisabad, Patna (800002)
							</Typography>
							<IconWrapper>
								<Location />
							</IconWrapper>
						</HelpWrapper>
						<LinkWrapper>
							<a href="https://www.instagram.com/wrd_bihar/" target="_blank" rel="noreferrer">
								<ImgStyle src={String(instagram)} alt="insta-logo" />
							</a>
							<a
								href="https://www.facebook.com/BiharWaterResourceDept"
								target="_blank"
								rel="noreferrer"
							>
								<ImgStyle src={String(facebook)} alt="fb-logo" />
							</a>
							<a href="https://www.youtube.com/@WRD_Bihar" target="_blank" rel="noreferrer">
								<ImgStyle src={String(youtube)} alt="youtube-logo" />
							</a>
							<a href="https://twitter.com/WRD_Bihar/" target="_blank" rel="noreferrer">
								<ImgStyle src={String(twitter)} alt="twitter-logo" />
							</a>
						</LinkWrapper>
					</HelpDeskInnerWrapper>
				</ContentWrapper>
			)}
		</>
	);
}
