import React, { useState, useEffect } from 'react';

import { MenuItem, FormControl, Select, SelectChangeEvent } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ENGLISH, HINDI } from 'src/common/utils/constants';
import { getCookie } from 'src/common/utils/axios';

export default function LanguagePopover(): React.ReactElement {
	const [selectLanguage, setSelectLanguage] = useState(HINDI);
	const { i18n } = useTranslation();

	let location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const languageValue = getCookie('language');
		if (languageValue === ENGLISH) {
			setSelectLanguage(ENGLISH);
			setCookie('language', ENGLISH, 360);
			changeLanguage(ENGLISH);
		} else if (languageValue === HINDI) {
			setSelectLanguage(HINDI);
			setCookie('language', HINDI, 360);
			changeLanguage(HINDI);
		} else {
			setSelectLanguage(HINDI);
			setCookie('language', HINDI, 360);
			changeLanguage(HINDI);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLanguageChange = (event: SelectChangeEvent) => {
		setSelectLanguage(event.target.value);
		changeLanguage(event.target.value);
		if (event.target.value === HINDI) {
			setCookie('language', HINDI, 360);
			navigate(location);
		} else {
			setCookie('language', ENGLISH, 360);
			navigate(location);
		}
	};

	const setCookie = (name: string, value: string, days: number) => {
		let expires = '';
		if (days) {
			let date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	};

	const changeLanguage = (language: string | undefined) => {
		i18n.changeLanguage(language);
	};

	return (
		<FormControl>
			<Select
				sx={{
					'.MuiOutlinedInput-notchedOutline': {
						border: 'none',
					},
					'.MuiOutlinedInput-input': {
						color: 'white',
						width: '5rem',
						padding: '0 !important',
					},
					'.MuiSelect-icon': {
						color: 'white',
					},
				}}
				value={selectLanguage}
				onChange={handleLanguageChange}
				displayEmpty
			>
				<MenuItem value={ENGLISH}>English</MenuItem>
				<MenuItem value={HINDI}>हिंदी</MenuItem>
			</Select>
		</FormControl>
	);
}
