import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme) {
	return {
		MuiTab: {
			styleOverrides: {
				root: {
					padding: 0,
					fontWeight: 400,
					fontSize: theme.typography.body1,
					color: theme.palette.text.primary,
					textTransform: 'inherit',
					'&.Mui-selected': {
						color: theme.palette.primary.main,
						fontWeight: 600,
					},
					'&:not(:last-of-type)': {
						marginRight: theme.spacing(2),
					},
					'@media (min-width: 600px)': {
						minWidth: 48,
					},
				},
				labelIcon: {
					minHeight: 48,
					flexDirection: 'row',
					'& > *:first-of-type': {
						marginBottom: 0,
						marginRight: theme.spacing(1),
					},
				},
				wrapper: {
					flexDirection: 'row',
					whiteSpace: 'nowrap',
				},
				textColorInherit: {
					opacity: 1,
					color: theme.palette.text.primary,
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					height: 4,
				},
			},
		},

		MuiTabPanel: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiTabScrollButton: {
			styleOverrides: {
				root: {
					width: 35,
					borderRadius: '50%',
					'&.Mui-disabled': {
						opacity: 0.3,
					},
				},
			},
		},
	};
}
