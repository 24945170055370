import { createSlice } from '@reduxjs/toolkit';

export const AlertSlice = createSlice({
  name: 'alert',
  initialState: {
    value: {
      id:'',
      edit:false,
    },
  },
  reducers: {
    AlertType: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { AlertType } = AlertSlice.actions;

export default AlertSlice.reducer;
