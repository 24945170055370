import { createSlice } from '@reduxjs/toolkit';

export const incidentSlice = createSlice({
  name: 'incident',
  initialState: {
    value: {
      incidentId: '',
      close : false,
      reopen:false,
    },
  },
  reducers: {
    incidentDetail: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { incidentDetail } = incidentSlice.actions;

export default incidentSlice.reducer;
