import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
	return {
		MuiAutocomplete: {
			styleOverrides: {
				paper: {
					boxShadow: theme.customShadows.dropdown,
					border: `1px solid ${theme.palette.secondary.main}`,
				},
				listbox: {
					padding: theme.spacing(0, 1),
					'& .MuiAutocomplete-option': {
						padding: theme.spacing(0.5, 1),
						margin: theme.spacing(0.5, 0),
						borderRadius: theme.shape.borderRadius,
					},
				},
			},
		},
	};
}
