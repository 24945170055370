export default function EditSVG(): React.ReactElement {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3474_25255)">
        <path
          d="M7.79199 2.83301H2.83366C2.45794 2.83301 2.0976 2.98226 1.83192 3.24794C1.56625 3.51362 1.41699 3.87395 1.41699 4.24967V14.1663C1.41699 14.5421 1.56625 14.9024 1.83192 15.1681C2.0976 15.4338 2.45794 15.583 2.83366 15.583H12.7503C13.126 15.583 13.4864 15.4338 13.7521 15.1681C14.0177 14.9024 14.167 14.5421 14.167 14.1663V9.20801"
          stroke="#2565AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1045 1.77116C13.3863 1.48936 13.7685 1.33105 14.167 1.33105C14.5655 1.33105 14.9477 1.48936 15.2295 1.77116C15.5113 2.05295 15.6696 2.43514 15.6696 2.83366C15.6696 3.23217 15.5113 3.61436 15.2295 3.89616L8.50033 10.6253L5.66699 11.3337L6.37533 8.50032L13.1045 1.77116Z"
          stroke="#2565AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3474_25255">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
