import { ReactNode, useState } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import axios from 'src/common/utils/axios';
import LoadingScreen from 'src/components/LoadingScreen';
import Login from 'src/pages/auth/Login';
import useAuth from 'src/common/hooks/useAuth';

// ----------------------------------------------------------------------

type AuthGuardProps = {
	children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
	const { isAuthenticated, isInitialized } = useAuth();

	const { pathname } = useLocation();
	const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

	if (!isInitialized) {
		return <LoadingScreen />;
	}

	if (!isAuthenticated) {
		if (pathname !== requestedLocation) {
			setRequestedLocation(pathname);
		}
		localStorage.removeItem('api_token');
		delete axios.defaults.headers.common.Authorization;
		return <Login />;
	}

	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
}
