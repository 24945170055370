import React, { useState, useEffect } from 'react';

import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useTranslation } from 'react-i18next';

import axios from 'src/common/utils/axios';
import MenuPopover from 'src/components/MenuPopover';
import { NOTIFICATION_FILTER_LIST } from 'src/common/utils/constants';
import { UnderlineText } from 'src/theme/overrides/SharedStylesComponent';
import useInterval from 'src/common/hooks/useInterval';
import { useSnackbar } from 'notistack';
import { NotificationType } from 'src/common/@types/dashboard';

const NotificationsCard = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
	borderRadius: '4px',
	padding: theme.spacing(2),
	marginBottom: theme.spacing(1.5),
	border: `1px solid ${theme.palette.secondary.main}`,
}));

const NotificationsWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(0, 1.5),
	overflow: 'auto',
	maxHeight: '400px',
	'&::-webkit-scrollbar': {
		width: '0.3em',
	},
	'&::-webkit-scrollbar-track': {
		boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
		backgroundColor: 'white',
		borderRadius: '10px',
		backgroundClip: 'content-box',
	},
	'&::-webkit-scrollbar-thumb': {
		borderRadius: '10px',
		backgroundColor: theme.palette.primary.main,
	},
}));

const BellIconStyle = styled(NotificationsOutlinedIcon)(({ theme }) => ({
	color: theme.palette.common.white,
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
	background: theme.palette.primary.light,
}));

type NotificationProps = {
	setNotificationCount: (count: number) => void;
};

export default function NotificationsPopover({
	setNotificationCount,
}: NotificationProps): React.ReactElement {
	const [noData, setNoData] = useState(true);
	const [open, setOpen] = useState<HTMLElement | null>(null);
	const [notifications, setNotifications] = useState<NotificationType[]>([]);
	const [filterValue, setFilterValue] = useState<string>('0');

	const { enqueueSnackbar } = useSnackbar();

	const { t } = useTranslation();

	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterValue((event.target as HTMLInputElement).value);
		getNotifications((event.target as HTMLInputElement).value);
	};

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
		setFilterValue('0');
	};

	const getNotifications = async (value: string) => {
		if (value === '0') {
			try {
				await axios.get('/api/notification/allnotifications').then((response) => {
					const { data } = response.data;
					if (data && data.length > 0) {
						setNotifications(data);
						setNoData(false);
						setNotificationCount(data.length);
					} else {
						setNotifications([]);
						setNoData(true);
						setNotificationCount(0);
					}
				});
			} catch (error) {
				enqueueSnackbar(error.message, { variant: 'error' });
			}
		} else {
			try {
				await axios
					.get('/api/notification/allnotifications', {
						params: {
							type: value,
						},
					})
					.then((response) => {
						const { data } = response.data;
						if (data && data.length > 0) {
							setNotifications(data);
							setNoData(false);
							setNotificationCount(data.length);
						} else {
							setNotifications([]);
							setNoData(true);
							setNotificationCount(0);
						}
					});
			} catch (error) {
				enqueueSnackbar(error.message, { variant: 'error' });
			}
		}
	};

	useEffect(() => {
		getNotifications(filterValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useInterval(async () => {
		getNotifications(filterValue);
	}, 60000);

	const clearNotification = async (id: number) => {
		try {
			await axios
				.post('/api/notification/mark-read', {
					notification_id: id,
				})
				.then((response) => {
					setNotifications(notifications.filter((notification) => notification.id !== id));
					setNotificationCount(notifications.length - 1);
				});
		} catch (error) {
			enqueueSnackbar(error.message, { variant: 'error' });
		}
	};

	const clearAll = async () => {
		try {
			await axios.get('/api/notification/mark-all-read').then((response) => {
				setNotifications([]);
				setNoData(true);
				setNotificationCount(0);
			});
		} catch (error) {
			enqueueSnackbar(error.message, { variant: 'error' });
		}
	};

	return (
		<>
			<Box onClick={handleOpen}>
				<BellIconStyle />
			</Box>

			<MenuPopover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				disabledArrow
				sx={{
					width: { xs: 360, lg: 460 },
					p: 0,
					maxHeight: noData ? 350 : 700,
					ml: { sm: 3.2 },
					overflow: 'hidden',
				}}
			>
				<HeaderWrapper>
					<Box display="flex" alignItems="center" justifyContent="end" pt={1} px={1.5}>
						<CloseIcon
							sx={{
								cursor: 'pointer',
								'&:hover': {
									backgroundColor: '#dddd',
									borderRadius: '50%',
								},
							}}
							fontSize="medium"
							onClick={handleClose}
							color="primary"
						/>
					</Box>
					<Box display="flex" alignItems="center" justifyContent="space-between" px={1.5} pb={1.5}>
						<Box display="flex" alignItems="center">
							<NotificationsOutlinedIcon color="primary" />
							<Typography variant="body2" color="primary" pl={0.3}>
								{t('dashboard:notifications')}
							</Typography>
						</Box>
						<UnderlineText variant="caption" color="primary" onClick={() => clearAll()}>
							{t('common:clearAll')}
						</UnderlineText>
					</Box>
				</HeaderWrapper>
				<Box p={1.5} display="flex" alignItems="center">
					<Typography variant="body2" mr={1.5}>
						{t('common:filter')}
					</Typography>
					<FormControl>
						<RadioGroup row value={filterValue} onChange={handleFilterChange}>
							{NOTIFICATION_FILTER_LIST.map((filter, i) => (
								<FormControlLabel
									key={i}
									value={filter.value}
									control={<Radio />}
									label={t(`common:${filter.label}`)}
								/>
							))}
						</RadioGroup>
					</FormControl>
				</Box>

				{noData ? (
					<Stack alignItems="center" justifyContent="center">
						<Typography color="primary" variant="h5" py={10}>
							{t('dashboard:noNotifications')}
						</Typography>
					</Stack>
				) : (
					<>
						<NotificationsWrapper>
							{notifications.map((notification: NotificationType, index: number) => (
								<NotificationsCard key={index}>
									<Box>
										<Typography variant="body1">{notification.text}</Typography>
										<Typography variant="body2" color="secondary.dark">
											{moment(notification.created_at).fromNow()}
										</Typography>
									</Box>
									<Button
										variant="contained"
										color="secondary"
										sx={{ color: (theme) => theme.palette.grey[900] }}
										onClick={() => clearNotification(notification.id)}
									>
										{t('common:clear')}
									</Button>
								</NotificationsCard>
							))}
						</NotificationsWrapper>
					</>
				)}
			</MenuPopover>
		</>
	);
}
