import React from 'react';

import { Button, styled, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { DialogWrapper } from './SharedDialogStyle';
import facebook from 'src/assets/images/Facebook.png';
import instagram from 'src/assets/images/Instagram.png';
import twitter from 'src/assets/images/Twitter.png';
import youtube from 'src/assets/images/YouTube.png';

type HelpDeskDialogProps = {
	open: boolean;
	onClose: () => void;
};

const MainWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
}));

const CloseIconWrapper = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	cursor: 'pointer',
}));

const MainContent = styled(Box)(({ theme }) => ({
	padding: theme.spacing(4, 0),
	display: 'flex',
	justifyContent: 'space-between',
	[theme.breakpoints.down('sm')]: {
		flexWrap: 'wrap',
	},
}));

const HelpWrapper = styled(Box)(({ theme }) => ({
	borderRadius: '4px',
	border: `1px solid ${theme.palette.text.primary}`,
	padding: theme.spacing(2.5, 2, 1.5, 2),
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(0.5, 1),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'absolute',
	top: '-1rem',
	backgroundColor: theme.palette.common.white,
}));

const LinkButton = styled(Button)(({ theme }) => ({
	color: theme.palette.text.primary,
	lineHeight: 1.5,
	fontSize: '16px !important',
	fontWeight: '600',
	textTransform: 'none',
	height: '23px',
	padding: '0px',
}));

const SocialWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(1),
	display: 'flex',
	border: `1px solid ${theme.palette.secondary.main}`,
	width: 'fit-content',
	[theme.breakpoints.down('sm')]: {
		flexWrap: 'wrap',
	},
}));

const ImgStyle = styled('img')(({ theme }) => ({
	margin: theme.spacing(0, 0.5),
}));

export default function HelpDeskDialog(props: HelpDeskDialogProps): React.ReactElement {
	return (
		<DialogWrapper open={props.open}>
			<MainWrapper>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h4" fontWeight={700} color="primary">
						FMISC Help Desk
					</Typography>
					<CloseIconWrapper onClick={props.onClose}>
						<CloseIcon />
					</CloseIconWrapper>
				</Box>
				<MainContent>
					<HelpWrapper>
						<LinkButton variant="text" href="tel:18003456145">
							1800 3456 145
						</LinkButton>
						<LinkButton variant="text" href="tel:06122256999">
							0612-2256999
						</LinkButton>
						<IconWrapper>
							<LocalPhoneOutlinedIcon />
						</IconWrapper>
					</HelpWrapper>
					<HelpWrapper mx={{ md: 2, sm: 2, xs: 0 }} my={{ md: 0, sm: 0, xs: 2 }}>
						<LinkButton variant="text" href="mailto:fmiscbihar@gmail.com">
							fmiscbihar@gmail.com
						</LinkButton>
						<LinkButton variant="text" href="mailto:fmisc.pat-bih@gov.in">
							fmisc.pat-bih@gov.in
						</LinkButton>
						<IconWrapper>
							<MailOutlineIcon />
						</IconWrapper>
					</HelpWrapper>
					<HelpWrapper>
						<Typography variant="subtitle1" fontWeight={300} lineHeight="15px" textAlign="center">
							2nd Floor, Jal Sansadhan Bhawan, Anisabad, Patna (800002)
						</Typography>
						<IconWrapper>
							<FmdGoodOutlinedIcon />
						</IconWrapper>
					</HelpWrapper>
				</MainContent>
				<Box display="flex" justifyContent="center" mb={2}>
					<SocialWrapper>
						<a
							href="https://www.instagram.com/wrd_bihar/"
							target="_blank"
							rel="noreferrer"
						>
							<ImgStyle src={String(instagram)} alt="insta-logo" />
						</a>
						<a
							href="https://www.facebook.com/BiharWaterResourceDept"
							target="_blank"
							rel="noreferrer"
						>
							<ImgStyle src={String(facebook)} alt="fb-logo" />
						</a>
						<a href="https://www.youtube.com/@WRD_Bihar" target="_blank" rel="noreferrer">
							<ImgStyle src={String(youtube)} alt="youtube-logo" />
						</a>
						<a href="https://twitter.com/WRD_Bihar/" target="_blank" rel="noreferrer">
							<ImgStyle src={String(twitter)} alt="twitter-logo" />
						</a>
					</SocialWrapper>
				</Box>
			</MainWrapper>
		</DialogWrapper>
	);
}
