import React, { useState } from 'react';

import * as Yup from 'yup';
import { InputAdornment, Typography, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import PhoneIcon from '@mui/icons-material/Phone';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { yupResolver } from '@hookform/resolvers/yup';

import { AUTH_PATHS, DOMAIN_PATHS } from 'src/routes/paths';
import {
	CAPTCHA_AUTH_KEY,
	LOGIN,
	PENDING,
	RESEND,
	RESETPASSWORD,
	UNVERIFIED,
} from 'src/common/utils/constants';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import {
	CaptchaWrapper,
	ErrorWrapper,
	EmailWrapper,
	FlexWrapper,
	LoginWrapper,
	MainWrapper,
	ResetButton,
	PhoneInputWrapper,
} from 'src/theme/overrides/SharedStylesComponent';
import { authType } from 'src/common/redux/slices/auth';
import AuthWrapper from 'src/components/auth/AuthWrapper';
import useAuth from 'src/common/hooks/useAuth';
import { useDispatch } from 'src/common/redux/store';

type LoginFormType = {
	phone: string;
	email: string;
	password: string;
};

export default function Login(): React.ReactElement {
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const { sendPhoneOtp, emailLogin } = useAuth();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [phone, setPhone] = useState('');
	const [showPhone, setShowPhone] = useState<boolean>(true);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [captcha, setCaptcha] = useState('');

	const { t } = useTranslation();

	const toggleView = () => {
		setShowPhone(!showPhone);
		setErrorMessage('');
		setCaptcha('');
	};

	const togglePassword = () => {
		setShowPassword(!showPassword);
	};

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email(t('auth:enterValidEmail').toString())
			.required(t('auth:emailRequired').toString()),
		password: Yup.string().required(t('auth:passwdRequired').toString()),
	});

	const PhoneSchema = Yup.object().shape({});

	const defaultValues = {
		phone: '',
		email: '',
		password: '',
	};

	const methods = useForm<LoginFormType>({
		resolver: !showPhone ? yupResolver(LoginSchema) : yupResolver(PhoneSchema),
		defaultValues,
	});

	const { handleSubmit } = methods;

	const handleCaptchaVerify = (value: string | null) => {
		value && setCaptcha(value);
	};

	const sendVerificationLink = async () => {
		dispatch(
			authType({
				action: RESEND,
			})
		);
		navigate(AUTH_PATHS.resetPasswordLink);
	};

	const resetPassword = () => {
		dispatch(
			authType({
				action: RESETPASSWORD,
			})
		);
		navigate(AUTH_PATHS.resetPasswordLink);
	};

	const onSubmit = async (data: LoginFormType) => {
		if (captcha === '') {
			enqueueSnackbar(t('auth:verifyRobot').toString(), { variant: 'error' });
			return;
		}
		if (showPhone) {
			if (phone) {
				if (phone.length < 10 || /^[6-9]\d{9}$/.test(phone) === false) {
					enqueueSnackbar(t('auth:invalidPhone').toString(), { variant: 'error' });
					return;
				}
				dispatch(
					authType({
						phoneValue: `+91${phone}`,
						action: LOGIN,
					})
				);
				try {
					setErrorMessage('');
					setLoading(true);
					await sendPhoneOtp(`+91${phone}`, LOGIN);
					navigate(AUTH_PATHS.loginOTP);
					enqueueSnackbar(t('auth:otpSent').toString(), { variant: 'success' });
				} catch (error) {
					if (error.message === PENDING) {
						dispatch(
							authType({
								phoneValue: `+91${phone}`,
								action: LOGIN,
								status: PENDING,
							})
						);
						navigate(AUTH_PATHS.status);
					} else {
						setErrorMessage(error.message);
					}
					setLoading(false);
				}
			} else {
				enqueueSnackbar(t('auth:enterMobile').toString(), { variant: 'error' });
				setLoading(false);
			}
		} else {
			try {
				setErrorMessage('');
				setLoading(true);
				await emailLogin(data.email, data.password);
				navigate(DOMAIN_PATHS.preference);
			} catch (error) {
				setErrorMessage(error.message);
				setLoading(false);
			}
		}
	};

	return (
		<AuthWrapper
			title="Login"
			heading={t('auth:login').toString()}
			primaryHeading
			lessPadding={showPhone ? false : true}
		>
			<MainWrapper>
				<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
					{showPhone ? (
						<LoginWrapper>
							<Box>
								<Typography variant="body1" fontWeight={700} mb={1} mt={4}>
									{t('auth:phoneNumber')}
								</Typography>
								<PhoneInputWrapper>
									<RHFTextField
										name="phone"
										label=""
										value={phone}
										type="text"
										onChange={(e) => {
											if (
												(e.target.value.length <= 10 && /^\d+$/.test(e.target.value)) ||
												e.target.value === ''
											) {
												setPhone(e.target.value);
											}
										}}
										greyVariant
										hideErrorSpace
										style={{ background: '#E9F0F7', border: 'none' }}
										inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Typography fontSize="0.9375rem" fontWeight={500} color="black">
														+91
													</Typography>
												</InputAdornment>
											),
										}}
										focused
									/>
								</PhoneInputWrapper>
								{errorMessage !== '' && errorMessage !== UNVERIFIED && (
									<ErrorWrapper>
										<Typography variant="caption" color="error">
											{errorMessage}
										</Typography>
									</ErrorWrapper>
								)}
								{errorMessage !== '' && errorMessage === UNVERIFIED && (
									<ErrorWrapper>
										<Box display="flex" alignItems="center" mt={1}>
											<ErrorIcon fontSize="small" color="error" />
											<Typography ml={0.5} variant="caption" color="error">
												{t('auth:unverifiedAccount')}
											</Typography>
										</Box>
										<Typography display="block" variant="caption">
											{t('auth:sentVerficationLink')}
										</Typography>
										<ResetButton variant="text" onClick={() => sendVerificationLink()}>
											{t('auth:resendLink')}
										</ResetButton>
									</ErrorWrapper>
								)}
								<CaptchaWrapper>
									<ReCAPTCHA
										sitekey={CAPTCHA_AUTH_KEY}
										onChange={handleCaptchaVerify}
										onExpired={() => setCaptcha('')}
									/>
								</CaptchaWrapper>
							</Box>
							<LoadingButton
								size="large"
								variant="contained"
								color="primary"
								type="submit"
								loading={loading}
							>
								{t('auth:sendOtp')}
							</LoadingButton>
							<FlexWrapper onClick={() => toggleView()}>
								<EmailWrapper>
									<EmailIcon fontSize="small" />
								</EmailWrapper>
								<Typography variant="subtitle2">{t('auth:loginWithEmail')}</Typography>
							</FlexWrapper>
						</LoginWrapper>
					) : (
						<LoginWrapper>
							<Box>
								<Typography variant="body1" fontWeight={700} mb={1} mt={4}>
									{t('common:email')}
								</Typography>
								<RHFTextField
									name="email"
									label=""
									type="string"
									greyVariant
									style={{ background: '#E9F0F7', border: 'none', fontSize: '16px' }}
									focused
									hideErrorSpace={errorMessage !== '' && errorMessage === UNVERIFIED ? true : false}
								/>
								{errorMessage !== '' && errorMessage === UNVERIFIED && (
									<ErrorWrapper>
										<Box display="flex" alignItems="center" mt={1}>
											<ErrorIcon fontSize="small" color="error" />
											<Typography ml={0.5} variant="caption" color="error">
												{t('auth:unverifiedAccount')}
											</Typography>
										</Box>
										<Typography display="block" variant="caption">
											{t('auth:sentVerficationLink')}
										</Typography>
										<ResetButton variant="text" onClick={() => sendVerificationLink()}>
											{t('auth:resendLink')}
										</ResetButton>
									</ErrorWrapper>
								)}
								<Typography variant="body1" fontWeight={700} mb={1} mt={4}>
									{t('auth:password')}
								</Typography>
								<RHFTextField
									name="password"
									label=""
									type={showPassword ? 'string' : 'password'}
									greyVariant
									style={{ background: '#E9F0F7', border: 'none', fontSize: '16px' }}
									InputProps={{
										endAdornment: (
											<InputAdornment
												position="end"
												sx={{
													backgroundColor: (theme) => theme.palette.primary.lighter,
													cursor: 'pointer',
												}}
												onClick={() => togglePassword()}
											>
												{showPassword ? (
													<VisibilityIcon fontSize="small" />
												) : (
													<VisibilityOffIcon fontSize="small" />
												)}
											</InputAdornment>
										),
									}}
								/>
								<Box>
									<Typography variant="caption">{t('auth:forgotPass')}</Typography>
									<ResetButton variant="text" onClick={resetPassword}>
										{t('auth:reset')}
									</ResetButton>
								</Box>
								{errorMessage !== '' && errorMessage !== UNVERIFIED && (
									<ErrorWrapper>
										<Typography variant="caption" color="error">
											{errorMessage}
										</Typography>
									</ErrorWrapper>
								)}
								<CaptchaWrapper>
									<ReCAPTCHA
										sitekey={CAPTCHA_AUTH_KEY}
										onChange={handleCaptchaVerify}
										onExpired={() => setCaptcha('')}
									/>
								</CaptchaWrapper>
							</Box>
							<LoadingButton
								size="large"
								variant="contained"
								color="primary"
								type="submit"
								loading={loading}
							>
								{t('auth:proceed')}
							</LoadingButton>
							<FlexWrapper onClick={() => toggleView()}>
								<EmailWrapper>
									<PhoneIcon fontSize="small" />
								</EmailWrapper>
								<Typography variant="subtitle2">{t('auth:loginWithPhone')}</Typography>
							</FlexWrapper>
						</LoginWrapper>
					)}
				</FormProvider>
				{/* <FooterWrapper>
					<Button variant="outlined" onClick={() => navigate(REGISTER_PATHS.contractSignup)}>
						{t('auth:signUpContract')}
					</Button>
					<Typography variant="caption" textAlign="center" mt={2}>
						{t('auth:contractMsg')}
					</Typography>
				</FooterWrapper> */}
			</MainWrapper>
		</AuthWrapper>
	);
}
