import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Button, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Location, Mail, Phone } from 'src/theme/overrides/SharedStylesComponent';
import { HEADER, NAVBAR } from 'src/config';
import { DOMAIN_PATHS } from 'src/routes/paths';
import facebook from 'src/assets/images/Facebook.png';
import instagram from 'src/assets/images/Instagram.png';
import MainHeader from './header';
import SideNav from './navbar/SideNav';
import twitter from 'src/assets/images/Twitter.png';
import useCollapseDrawer from 'src/common/hooks/useCollapseDrawer';
import useSettings from 'src/common/hooks/useSettings';
import useResponsive from 'src/common/hooks/useResponsive';
import youtube from 'src/assets/images/YouTube.png';

// ----------------------------------------------------------------------

type MainStyleProps = {
	collapseClick: boolean;
};

const MainStyle = styled('main', {
	shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
	paddingTop: HEADER.MOBILE_HEIGHT + 24,
	[theme.breakpoints.up('lg')]: {
		paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
		width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
		height: '100%',

		transition: theme.transitions.create('margin-left', {
			duration: theme.transitions.duration.shorter,
		}),
		...(collapseClick && {
			marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
			width: '100%',
		}),
	},
}));

const MainContent = styled(Box)(({ theme }) => ({
	paddingBottom: HEADER.MOBILE_HEIGHT + 24,
	flexGrow: 1,
	paddingLeft: 12,
	paddingRight: 12,

	[theme.breakpoints.up('lg')]: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
		// transition: theme.transitions.create('margin-left', {
		// 	duration: theme.transitions.duration.shorter,
		// }),
		// ...(collapseClick && {
		// 	marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
		// }),
	},
}));
const FooterWrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
	background: theme.palette.primary.light,
	display: 'flex',
	justifyContent: 'space-between',
	padding: theme.spacing(1.2),

	[theme.breakpoints.up('lg')]: {
		padding: theme.spacing(2),
		right: 0,
		position: 'fixed',
		bottom: 0,
		width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
		...(collapseClick && {
			marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
			width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
		}),
	},
}));

const MobileFooterWrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
	background: theme.palette.primary.main,
	display: 'flex',
	justifyContent: 'space-between',
	padding: theme.spacing(1.2),

	[theme.breakpoints.up('lg')]: {
		padding: theme.spacing(2),
		right: 0,
		position: 'fixed',
		bottom: 0,
		width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
		...(collapseClick && {
			marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
			width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
		}),
	},
}));

const LinkStyle = styled(Typography)(({ theme }) => ({
	cursor: 'pointer',
	paddingLeft: theme.spacing(2),
	fontSize: '12px',
	fontWeight: 500,
	[theme.breakpoints.down('sm')]: {
		paddingLeft: theme.spacing(1),
	},
}));

const MobileLinkStyle = styled(Typography)(({ theme }) => ({
	cursor: 'pointer',
	paddingLeft: theme.spacing(2),
	fontSize: '9px',
	fontWeight: 300,
	color: 'white',
	[theme.breakpoints.down('sm')]: {
		paddingLeft: theme.spacing(1),
	},
}));

const FlexWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
}));
const ImgStyle = styled('img')(({ theme }) => ({
	marginRight: theme.spacing(1),
	width: '15px',
	height: '15px',
}));

const LinkWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	marginTop: theme.spacing(0.7),
	flexWrap: 'wrap',
}));

const BottomWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	marginTop: theme.spacing(0.7),
	flexWrap: 'wrap',
	borderTop: '1px solid',
	borderColor: theme.palette.primary.light,
	padding: theme.spacing(2, 0, 1, 0),
}));

const LinkButton = styled(Button)(({ theme }) => ({
	color: theme.palette.primary.lighter,
	padding: '0px',
	lineHeight: 1.5,
	fontSize: '11px !important',
	paddingRight: theme.spacing(0.5),
	textTransform: 'none',
	height: '20px !important',
	fontWeight: '300',
}));

// ----------------------------------------------------------------------

export default function MainLayout() {
	const { collapseClick, isCollapse } = useCollapseDrawer();
	const { themeLayout } = useSettings();
	const [open, setOpen] = useState(false);
	const verticalLayout = themeLayout === 'vertical';
	const isDesktop = useResponsive('up', 'lg');
	const navigate = useNavigate();
	const { t } = useTranslation();

	if (verticalLayout) {
		return (
			<>
				<MainHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />
				<SideNav isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

				<Box
					component="main"
					sx={{
						px: { lg: 2 },
						pt: {
							xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
							lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
						},
						pb: {
							xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
							lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
						},
					}}
				>
					<Outlet />
				</Box>
			</>
		);
	}

	return (
		<Box
			sx={{
				display: { lg: 'flex' },
				minHeight: { lg: 1 },
			}}
		>
			<MainHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

			<SideNav isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

			<MainStyle collapseClick={collapseClick}>
				<MainContent>
					<Outlet />
				</MainContent>
				{isDesktop ? (
					<FooterWrapper collapseClick={collapseClick}>
						<a
							href="https://wrd.fmiscwrdbihar.gov.in/"
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: 'none' }}
						>
							<Typography fontSize={12} fontWeight={500}>
								{t('common:wrd')}
							</Typography>
						</a>
						<Box display="flex">
							<LinkStyle onClick={() => navigate(DOMAIN_PATHS.disclaimer)}>
								{t('auth:disclaimer')}
							</LinkStyle>
							<LinkStyle onClick={() => navigate(DOMAIN_PATHS.terms)}>{t('auth:terms')}</LinkStyle>
							<LinkStyle onClick={() => navigate(DOMAIN_PATHS.privacy)}>
								{t('auth:privacy')}
							</LinkStyle>
						</Box>
					</FooterWrapper>
				) : (
					<MobileFooterWrapper collapseClick={collapseClick}>
						<Box width="100%">
							<FlexWrapper>
								<Typography variant="body1" fontWeight={600} color="white" lineHeight="19px">
									{t('common:FMISCHelpdesk')}
								</Typography>
								<Box display="flex">
									<a href="https://www.instagram.com/wrd_bihar/" target="_blank" rel="noreferrer">
										<ImgStyle src={String(instagram)} alt="insta-logo" />
									</a>
									<a
										href="https://www.facebook.com/BiharWaterResourceDept"
										target="_blank"
										rel="noreferrer"
									>
										<ImgStyle src={String(facebook)} alt="fb-logo" />
									</a>
									<a href="https://www.youtube.com/@WRD_Bihar" target="_blank" rel="noreferrer">
										<ImgStyle src={String(youtube)} alt="youtube-logo" />
									</a>
									<a href="https://twitter.com/WRD_Bihar/" target="_blank" rel="noreferrer">
										<ImgStyle src={String(twitter)} alt="twitter-logo" />
									</a>
								</Box>
							</FlexWrapper>
							<LinkWrapper>
								<Phone />
								<LinkButton variant="text" href="tel:18003456145">
									18003456145 {t('auth:tollFree')},
								</LinkButton>
								<LinkButton variant="text" href="tel:06122256999">
									0612-2256999 {t('auth:office')}
								</LinkButton>
							</LinkWrapper>
							<LinkWrapper sx={{ flexWrap: 'nowrap' }}>
								<Mail />
								<LinkWrapper sx={{ marginTop: '0px' }}>
									<LinkButton variant="text" href="mailto:fmiscbihar@gmail.com">
										fmiscbihar@gmail.com,
									</LinkButton>

									<LinkButton variant="text" href="mailto:fmisc.pat-bih@gov.in">
										fmisc.pat-bih@gov.in
									</LinkButton>
								</LinkWrapper>
							</LinkWrapper>
							<LinkWrapper sx={{ flexWrap: 'nowrap' }}>
								<Location />
								<Typography
									color="primary.lighter"
									variant="caption"
									fontSize="11px"
									fontWeight={300}
									sx={{ wordBreak: 'break-all' }}
								>
									2nd Floor, Jal Sansadhan Bhawan, Anisabad, Patna - 800002
								</Typography>
							</LinkWrapper>
							<BottomWrapper>
								<FlexWrapper>
									<a
										href="https://wrd.fmiscwrdbihar.gov.in/"
										target="_blank"
										rel="noreferrer"
										style={{ textDecoration: 'none' }}
									>
										<Typography fontSize={9} fontWeight={300} color="white">
											{t('auth:wrd')}
										</Typography>
									</a>
									<Box display="flex">
										<LinkStyle onClick={() => navigate(DOMAIN_PATHS.disclaimer)}>
											{t('auth:disclaimer')}
										</LinkStyle>
										<MobileLinkStyle>{t('auth:terms')}</MobileLinkStyle>
										<MobileLinkStyle>{t('auth:privacy')}</MobileLinkStyle>
									</Box>
								</FlexWrapper>
							</BottomWrapper>
						</Box>
					</MobileFooterWrapper>
				)}
			</MainStyle>
		</Box>
	);
}
