import React from 'react';

import { Box, Button, Grid, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Location, Mail, Phone } from 'src/theme/overrides/SharedStylesComponent';
import { AUTH_PATHS, DOMAIN_PATHS } from 'src/routes/paths';
import { AuthWrapperType } from 'src/common/@types/auth';
import facebook from 'src/assets/images/Facebook.png';
import LanguagePopover from 'src/theme/layouts/dashboard/header/LanguagePopover';
import Logo from 'src/assets/images/Icon.png';
import LogoMobileSVG from 'src/assets/images/SVG/LogoMobileSVG';
import LogoSVG from 'src/assets/images/SVG/LogoSVG';
import instagram from 'src/assets/images/Instagram.png';
import CM from 'src/assets/images/cm.png';
import Minister from 'src/assets/images/minister.png';
import Secretary from 'src/assets/images/secretary.png';
import Page from 'src/components/Page';
import twitter from 'src/assets/images/Twitter.png';
import youtube from 'src/assets/images/YouTube.png';

const OuterWrapper = styled(Box)(() => ({
	minHeight: '100vh',
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
	height: '10px',
	backgroundColor: theme.palette.success.main,
}));

const MainWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	width: '50%',
	height: '100%',
	paddingTop: '10%',
	[theme.breakpoints.down('lg')]: {
		width: '70%',
		paddingTop: '10%',
	},
	[theme.breakpoints.up('lg')]: {
		paddingTop: '15%',
	},
	[theme.breakpoints.up('xl')]: {
		paddingTop: '20%',
	},
	[theme.breakpoints.down('sm')]: {
		width: '85%',
	},
}));

const MinisterImage = styled('img')(() => ({
	borderRadius: '50%',
	marginRight: '0.3rem',
}));

const LeftGridItem = styled(Grid)(({ theme }) => ({
	background: theme.palette.primary.main,
	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
}));

const HeaderGridItem = styled(Grid)(({ theme }) => ({
	display: 'none',
	padding: theme.spacing(2),
	backgroundColor: theme.palette.primary.main,
	[theme.breakpoints.down('md')]: {
		justifyContent: 'space-between',
		alignItems: 'center',
		display: 'flex',
	},
}));

const FooterGrid = styled(Grid)(({ theme }) => ({
	display: 'none',
	padding: theme.spacing(1, 2),
	width: '100%',
	backgroundColor: theme.palette.primary.main,
	[theme.breakpoints.down('md')]: {
		display: 'flex',
		flexDirection: 'column',
	},
}));

const HeaderLogo = styled('img')(() => ({
	width: '94px',
}));

const LinkWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	marginTop: theme.spacing(1),
	flexWrap: 'wrap',
}));

const CopyrightWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginTop: theme.spacing(2),
	borderTop: '0.5px solid',
	borderColor: theme.palette.primary.lighter,
	paddingTop: theme.spacing(1),
	flexWrap: 'wrap',
}));

const ImgStyle = styled('img')(({ theme }) => ({
	marginRight: theme.spacing(1),
	cursor: 'pointer',
	height: '20px',
	width: '20px',
}));

const HeadText = styled(Typography)(({ theme }) => ({
	color: 'white',
	fontWeight: 500,
	lineHeight: 1.5,
	fontSize: '14px',
	[theme.breakpoints.down('sm')]: {
		fontSize: '11px !important',
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(2, 0, 1.5, 0),
	fontSize: '30px',
	fontWeight: 500,
	lineHeight: '23px',
	[theme.breakpoints.up('lg')]: {
		fontSize: '35px',
	},
	[theme.breakpoints.down('md')]: {
		fontSize: '25px',
	},
}));
const Footer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: theme.spacing(1.5, 2, 0.5, 2),
}));

const LinkButton = styled(Button)(({ theme }) => ({
	color: 'white',
	padding: '0px',
	lineHeight: 1.5,
	fontSize: '13px !important',
	paddingRight: theme.spacing(1),
	textTransform: 'none',
	height: '20px !important',
}));

const LanguageWrapper = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: '12px',
	right: '22px',
	backgroundColor: theme.palette.primary.main,
	padding: theme.spacing(1),
	borderRadius: '4px',
}));

export default function AuthWrapper(props: AuthWrapperType): React.ReactElement {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Page title={props.title} removePadding>
			<Grid container>
				<LeftGridItem
					item
					sm={12}
					md={5}
					lg={5}
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
				>
					<Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
						<HeaderLogo
							sx={{ cursor: 'pointer' }}
							onClick={() => navigate(AUTH_PATHS.root)}
							src={Logo}
							alt="logo"
						/>
						<Box display="flex" alignItems="center">
							<Box display="flex" alignItems="center">
								<MinisterImage width={60} src={CM} alt="Chief Minister" />
								<MinisterImage width={50} src={Minister} alt="Minister" />
								<MinisterImage width={40} src={Secretary} alt="Secretary" />
							</Box>

							<Typography color="white" variant="subtitle1" pl={1.5}>
								{t('auth:initiative')}
							</Typography>
						</Box>
					</Box>
					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
						<LogoSVG />
					</Box>
					<Footer>
						<Typography variant="subtitle1" color="white" fontWeight={700} mb={2}>
							BeFIQR - Bihar eSystem for Flood & Irrigation Quick Response
						</Typography>
						<Typography variant="subtitle1" color="white" fontWeight={700}>
							{t('auth:support')}
						</Typography>
						<LinkWrapper>
							<Phone />
							<LinkButton variant="text" href="tel:18003456145">
								18003456145 ({t('auth:tollFree')}),
							</LinkButton>
							<LinkButton variant="text" href="tel:06122256999">
								0612-2256999 ({t('auth:office')})
							</LinkButton>
						</LinkWrapper>
						<LinkWrapper sx={{ flexWrap: 'nowrap' }}>
							<Mail />
							<LinkWrapper sx={{ marginTop: '0px' }}>
								<LinkButton variant="text" href="mailto:fmiscbihar@gmail.com">
									fmiscbihar@gmail.com,
								</LinkButton>

								<LinkButton variant="text" href="mailto:fmisc.pat-bih@gov.in">
									fmisc.pat-bih@gov.in,
								</LinkButton>
								<LinkButton variant="text" href="mailto:fmisc_bihar@yahoo.co.in">
									fmisc_bihar@yahoo.co.in
								</LinkButton>
							</LinkWrapper>
						</LinkWrapper>
						<LinkWrapper sx={{ flexWrap: 'nowrap' }}>
							<Location />
							<Typography color="white" variant="caption" sx={{ wordBreak: 'break-all' }}>
								2nd Floor, Jal Sansadhan Bhawan, Anisabad, Patna - 800002
							</Typography>
						</LinkWrapper>
						<LinkWrapper>
							<a href="https://www.instagram.com/wrd_bihar/" target="_blank" rel="noreferrer">
								<ImgStyle src={String(instagram)} alt="insta-logo" />
							</a>
							<a
								href="https://www.facebook.com/BiharWaterResourceDept"
								target="_blank"
								rel="noreferrer"
							>
								<ImgStyle src={String(facebook)} alt="fb-logo" />
							</a>
							<a href="https://www.youtube.com/@WRD_Bihar" target="_blank" rel="noreferrer">
								<ImgStyle src={String(youtube)} alt="youtube-logo" />
							</a>
							<a href="https://twitter.com/WRD_Bihar/" target="_blank" rel="noreferrer">
								<ImgStyle src={String(twitter)} alt="twitter-logo" />
							</a>
						</LinkWrapper>

						<CopyrightWrapper>
							<a
								href="https://wrd.fmiscwrdbihar.gov.in/"
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'none' }}
							>
								<Typography color="white" flex={1} variant="caption">
									{t('auth:wrd')}
								</Typography>
							</a>
							<Typography
								color="white"
								sx={{ cursor: 'pointer' }}
								variant="caption"
								pr={2}
								onClick={() => navigate(DOMAIN_PATHS.terms)}
							>
								{t('auth:terms')}
							</Typography>
							<Typography
								color="white"
								sx={{ cursor: 'pointer' }}
								variant="caption"
								onClick={() => navigate(DOMAIN_PATHS.privacy)}
							>
								{t('auth:privacy')}
							</Typography>
						</CopyrightWrapper>
						<Box display="flex" justifyContent="space-between" mb={0.5}>
							<Typography
								color="white"
								sx={{ cursor: 'pointer' }}
								variant="caption"
								onClick={() => navigate(DOMAIN_PATHS.disclaimer)}
							>
								{t('auth:disclaimer')}
							</Typography>
							<Typography
								color="white"
								sx={{ cursor: 'pointer' }}
								variant="caption"
								onClick={() => navigate(DOMAIN_PATHS.copyright)}
							>
								{t('auth:copyrightPolicy')}
							</Typography>
						</Box>
					</Footer>
				</LeftGridItem>

				<HeaderGridItem item sm={12} xs={12}>
					<LogoMobileSVG />
					<Box
						width={1}
						display="flex"
						alignItems="center"
						justifyContent={{ xs: 'flex-end', sm: 'flex-end', md: 'space-between' }}
					>
						<Box display="flex" alignItems="center">
							<MinisterImage width={40} src={CM} alt="Chief Minister" />
							<MinisterImage width={30} src={Minister} alt="Minister" />
							<MinisterImage width={20} src={Secretary} alt="Secretary" />
						</Box>

						<HeadText variant="subtitle1" pl={{ xs: 0, sm: 0, md: 1.5 }}>
							{t('auth:initiative')}
						</HeadText>
					</Box>
				</HeaderGridItem>
				<Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{ overflowY: 'auto' }}>
					<OuterWrapper>
						{props.progress && <ProgressWrapper sx={{ width: props.progress }} />}
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							sx={{ height: props.progress ? 'unset' : '100%', position: 'relative' }}
						>
							<LanguageWrapper>
								<LanguagePopover />
							</LanguageWrapper>

							<MainWrapper sx={{ paddingTop: props.lessPadding ? '5% !important' : 'unset' }}>
								<Heading color={props.primaryHeading ? 'primary' : 'black'}>
									{props.heading}
								</Heading>
								<Typography variant="h6" color="primary.main">
									{props.subheading}
								</Typography>

								{props.children}

								{props.button}
							</MainWrapper>
						</Box>
					</OuterWrapper>
				</Grid>
				<FooterGrid item xs={12} sm={12}>
					<Typography variant="subtitle1" color="white" fontWeight={700}>
						{t('auth:support')}
					</Typography>
					<LinkWrapper sx={{ flexWrap: 'nowrap' }}>
						<Phone />
						<LinkWrapper sx={{ marginTop: '0px' }}>
							<LinkButton variant="text" href="tel:18003456145">
								18003456145 ({t('auth:tollFree')}),
							</LinkButton>
							<LinkButton variant="text" href="tel:06122256999">
								0612-2256999 ({t('auth:office')})
							</LinkButton>
						</LinkWrapper>
					</LinkWrapper>
					<LinkWrapper>
						<a href="https://www.instagram.com/wrd_bihar/" target="_blank" rel="noreferrer">
							<ImgStyle src={String(instagram)} alt="insta-logo" />
						</a>
						<a
							href="https://www.facebook.com/BiharWaterResourceDept"
							target="_blank"
							rel="noreferrer"
						>
							<ImgStyle src={String(facebook)} alt="fb-logo" />
						</a>
						<a href="https://www.youtube.com/@WRD_Bihar" target="_blank" rel="noreferrer">
							<ImgStyle src={String(youtube)} alt="youtube-logo" />
						</a>
						<a href="https://twitter.com/WRD_Bihar/" target="_blank" rel="noreferrer">
							<ImgStyle src={String(twitter)} alt="twitter-logo" />
						</a>
					</LinkWrapper>
				</FooterGrid>
			</Grid>
		</Page>
	);
}
