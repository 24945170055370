import { Dialog, styled } from '@mui/material';

export const DialogWrapper = styled(Dialog)(({ theme }) => ({
	backgroundColor: 'rgba(0,0,0,0.5)',
	' .MuiDialog-container .MuiPaper-root': {
		boxShadow: 'none',
		outline: 'none',
	},
	'& .MuiDialog-container': {
		'& .MuiPaper-root': {
			background: theme.palette.common.white,
			borderRadius: '4px',
		},
	},
}));
