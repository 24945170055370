import { createContext, ReactNode, useEffect, useReducer } from 'react';

// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from 'src/common/@types/auth';
// utils
import axios from 'src/common/utils/axios';
import { isValidToken, setNewUser, setSession } from 'src/common/utils/jwt';
import { LOGIN } from '../utils/constants';

// ----------------------------------------------------------------------

export enum Types {
	Initial = 'INITIALIZE',
	Logout = 'LOGOUT',
	VerifyOtp = 'VERIFYOTP',
	SendOtp = 'SENDOTP',
	EmailLogin = 'EMAILLOGIN',
	tokenLogin = "TOKENLOGIN",
}

type JWTAuthPayload = {
	[Types.Initial]: {
		isAuthenticated: boolean;
		user: AuthUser;
	};

	[Types.Logout]: undefined;

	[Types.VerifyOtp]: {
		user: AuthUser;
	};
	[Types.SendOtp]: undefined;
	[Types.EmailLogin]: {
		user: AuthUser;
	};
	[Types.tokenLogin]: {
		user: AuthUser;
	};
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
	switch (action.type) {
		case 'INITIALIZE':
			return {
				isAuthenticated: action.payload.isAuthenticated,
				isInitialized: true,
				user: action.payload.user,
			};
		case 'LOGOUT':
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};

		case 'SENDOTP':
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};

		case 'VERIFYOTP':
			return {
				...state,
				isAuthenticated: true,
				user: action.payload.user,
			};

		case 'EMAILLOGIN':
			return {
				...state,
				isAuthenticated: true,
				user: action.payload.user,
			};
			case 'TOKENLOGIN':
				return {
					...state,
					isAuthenticated: false,
					user: action.payload.user,
				};
		default:
			return state;
	}
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
	children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
	const [state, dispatch] = useReducer(JWTReducer, initialState);

	useEffect(() => {
		const initialize = async () => {
			try {
				const api_token = window.localStorage.getItem('api_token');
				if (api_token && isValidToken(api_token)) {
					setSession(api_token);
					const response = await axios.get('/api/user/profile');
					const { data } = response.data;
					dispatch({
						type: Types.Initial,
						payload: {
							isAuthenticated: true,
							user: data,
						},
					});
				} else {
					dispatch({
						type: Types.Initial,
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err) {
				console.error(err);
				dispatch({
					type: Types.Initial,
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		};

		initialize();
	}, []);

	const sendPhoneOtp = async (phone_number: string,action:string) => {
		const response = await axios.post('/api/auth/send-otp', {
			account_type:'official',
			action:action,
			phone_number,

		});
		dispatch({
			type: Types.SendOtp,
		});
		return response;
	};

	const verifyPhoneOtp = async (phone_number: string, otp: string,action:string) => {
		const response = await axios.post('/api/auth/verify-otp', {
			phone_number,
			action:action,
			account_type:'official',
			otp,
		});
		const { data } = response.data;
		if(action === LOGIN){
			setSession(data.api_token);
			setNewUser(data.new_user);
	
			dispatch({
				type: Types.VerifyOtp,
				payload: {
					user: data.user,
				},
			});
		}
			
		
	};

	const emailLogin = async (email: string, password: string) => {
		const response = await axios.post('/api/auth/email-login', {
			email,
			password,
		});
		const { data } = response.data;

		setSession(data.api_token);
		setNewUser(data.new_user);

		dispatch({
			type: Types.VerifyOtp,
			payload: {
				user: data.user,
			},
		});
	};

	const tokenLogin = async (token:string) => {
		const response = await axios.get('/api/auth/verify', {
			params:{
				token:token,
			}
		});
		const { data } = response.data;
		if(data){
			setSession(data.api_token);
			dispatch({
				type: Types.tokenLogin,
				payload: {
					user: data.user,
				},
			});
		}

	};

	const tokenAuthenticate = async (user:any) => {
		dispatch({
			type: Types.VerifyOtp,
			payload: {
				user: user,
			},
		});
	};


	const profileData = async () => {
		const response = await axios.get('/api/user/profile');
		const { data } = response.data;
		dispatch({
			type: Types.Initial,
			payload: {
				isAuthenticated: true,
				user: data,
			},
		});
	};

	const logout = async () => {
		setSession(null);
		dispatch({ type: Types.Logout });
		let nepalData = localStorage.getItem('redux-nepal');
		localStorage.clear();
		if (nepalData) {
			localStorage.setItem('redux-nepal', nepalData);
		}
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'jwt',
				sendPhoneOtp,
				verifyPhoneOtp,
				emailLogin,
				profileData,
				logout,
				tokenLogin,
				tokenAuthenticate
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
