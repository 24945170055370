import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { Box, Popover, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DOMAIN_PATHS } from 'src/routes/paths';
import EditSVG from 'src/assets/images/SVG/EditSVG';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ProfilePic from 'src/assets/images/profile.png';
import useAuth from 'src/common/hooks/useAuth';
import HelpDeskDialog from 'src/components/dialog/HelpDeskDialog';

const ImageStyle = styled('img')(() => ({
	width: '2rem',
	height: '2rem',
	borderRadius: '50%',
	objectFit: 'cover',
	cursor: 'pointer',
}));

const PopoverUpperWrapper = styled(Box)(({ theme }) => ({
	height: '4rem',
	borderBottom: `1px solid ${theme.palette.grey[200]}`,
	background: theme.palette.primary.lighter,
}));
const PopoverMiddleWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(4, 2, 3, 2),
	borderBottom: `1px solid ${theme.palette.grey[200]}`,
	background: theme.palette.common.white,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '19rem',
}));
const PopoverBottomWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	background: theme.palette.common.white,
	display: 'flex',
	justifyContent: 'space-between',
}));
const PopoverBottomInnerWrapper = styled(Box)(() => ({
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	width: 'fit-content',
}));
const PopoverImageStyle = styled('img')(() => ({
	width: '3.5rem',
	height: '3.5rem',
	borderRadius: '50%',
	objectFit: 'cover',
	position: 'absolute',
	top: '2rem',
}));

export default function AccountPopover(): React.ReactElement {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const navigate = useNavigate();
	const { user } = useAuth();
	const [openHelpDialog, setOpenHelpDialog] = useState<boolean>(false);

	const { t } = useTranslation();

	const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<ImageStyle
				src={
					user
						? user.profile_picture
							? user.profile_picture.thumbnail_url
								? user.profile_picture.thumbnail_url
								: ProfilePic
							: ProfilePic
						: ProfilePic
				}
				alt="Profile picture"
				onClick={(e: any) => {
					handlePopoverOpen(e);
				}}
			/>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<PopoverUpperWrapper />
				<PopoverMiddleWrapper>
					<PopoverImageStyle
						src={
							user
								? user.profile_picture
									? user.profile_picture.thumbnail_url
										? user.profile_picture.thumbnail_url
										: ProfilePic
									: ProfilePic
								: ProfilePic
						}
						alt="Profile picture"
					/>
					<Typography variant="body2" fontWeight={600}>
						{user ? (user.full_name ? user.full_name : 'User') : 'User'}
					</Typography>
					{user && user.designation && user.designation.label && (
						<Typography variant="body2" fontWeight={300}>
							{user.designation.label}
						</Typography>
					)}
					{user && user.email && (
						<Typography variant="body2" fontWeight={300}>
							{user.email}
						</Typography>
					)}
					{user && user.phone_number && (
						<Typography variant="body2" fontWeight={300}>
							{user.phone_number}
						</Typography>
					)}
				</PopoverMiddleWrapper>
				<PopoverBottomWrapper>
					<PopoverBottomInnerWrapper
						onClick={() => {
							setAnchorEl(null);
							navigate(DOMAIN_PATHS.profile);
						}}
					>
						<EditSVG />
						<Typography variant="body2" fontWeight={600} color="primary" pl={1} pt={0.2}>
							{t('dashboard:editProfile')}
						</Typography>
					</PopoverBottomInnerWrapper>
					<PopoverBottomInnerWrapper
						onClick={() => {
							setAnchorEl(null);
							setOpenHelpDialog(true);
						}}
					>
						<HelpOutlineIcon color="primary" fontSize="small" />
						<Typography variant="body2" fontWeight={600} color="primary" pl={0.6} pt={0.2}>
						{t('dashboard:helpdesk')}
						</Typography>
					</PopoverBottomInnerWrapper>
				</PopoverBottomWrapper>
			</Popover>
			<HelpDeskDialog open={openHelpDialog} onClose={() => setOpenHelpDialog(false)} />
		</>
	);
}
