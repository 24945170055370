import axios from 'axios';

// config
import { HOST_API } from 'src/config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
	baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status === 504) {
			console.log('Gateway Timeout!');
		}
		if (error.response.status === 401) {
			window.location.reload();
		}
		return Promise.reject(
			(error.response && error.response.data) ||
				'There seems to be a problem right now, please re-try in sometime!'
		);
	}
);
// INTERCEPTOR FOR LANGUAGE
axiosInstance.interceptors.request.use(
	(config) => {
		if (!config?.headers) {
			throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
		}
		const languageValue = getCookie('language');
		if (languageValue) {
			config.headers.language = languageValue;
		}

		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

export const getCookie = (name: string) => {
	let nameEQ = name + '=';
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
};

export default axiosInstance;
