import { ReactNode } from 'react';

import {
	LinkProps,
	ListItemButton,
	ListItemButtonProps,
	ListItemIcon,
	ListItemText,
	styled,
} from '@mui/material';

import { ICON, NAVBAR } from 'src/config';

type IProps = LinkProps & ListItemButtonProps;

// @ts-ignore
interface ListItemStyleProps extends IProps {
	component?: ReactNode;
	to?: string;
	activeRoot?: boolean;
	activeSub?: boolean;
	subItem?: boolean;
}

export const ListItemStyle = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})<ListItemStyleProps>(({ activeRoot, activeSub, subItem, theme }) => ({
	...theme.typography.body2,
	position: 'relative',
	height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
	textTransform: 'capitalize',
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(1.5),
	marginBottom: theme.spacing(1.5),
	marginTop: theme.spacing(1),
	color: '#FAFAFA',
	fontWeight: 500,
	':hover': {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,
		fontWeight: 700,
	},
	//borderRadius: theme.shape.borderRadius,
	// activeRoot
	...(activeRoot && {
		...theme.typography.subtitle2,
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,
		fontWeight: 700,
	}),
	// activeSub
	...(activeSub && {
		...theme.typography.subtitle2,
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,
		fontWeight: 700,
	}),
	// subItem
	...(subItem && {
		height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
		':hover': {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.primary.main,
			fontWeight: 700,
		},
	}),
}));

interface ListItemTextStyleProps extends ListItemButtonProps {
	isCollapse?: boolean;
}

export const ListItemTextStyle = styled(ListItemText, {
	shouldForwardProp: (prop) => prop !== 'isCollapse',
})<ListItemTextStyleProps>(({ isCollapse, theme }) => ({
	//whiteSpace: 'nowrap',
	transition: theme.transitions.create(['width', 'opacity'], {
		duration: theme.transitions.duration.shorter,
	}),
	...(isCollapse && {
		width: 0,
		opacity: 0,
	}),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
	width: ICON.NAVBAR_ITEM,
	height: ICON.NAVBAR_ITEM,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	'& svg': { width: '100%', height: '100%' },
});
